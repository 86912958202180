import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { ShoppingBasketOutlined, Send, ErrorOutline, WarningRounded, DeleteForever } from '@material-ui/icons/'
import {
  IconButton, ButtonBase, TextField, Grid, Button, CircularProgress, Table, Checkbox,
} from '@material-ui/core/'
import axios from 'axios'
import ApiLink from '../components/ApiLink'
import master from '../images/master.png'
import rocket from '../images/rocket.png'
import nogod from '../images/nogod.png'
import visa from '../images/visa.png'
import bkash2 from '../images/bkash2.png'
import cod from '../images/cod.png'

const styles = {
  wrapper: {
    padding: 10,
    background: '#e8edf161',
  },
  paymentSlider: {
    margin: 10,
    padding: 10,
    background: 'white',
    borderRadius: 10,
  },
  error_msg: {
    display: 'flex',
    alignContent: 'center',
    fontSize: 12,
    padding: 5,
    color: 'darkred',
    margin: 4,
    borderRadius: 8,
  },
  pdtListItem: {
    display: 'flex',
    padding: 5,
    background: '#fff',
    borderRadius: 10,
    marginBottom: 13,
    boxShadow: '0px 0px 5px 0px rgba(157,171,209,.4)',
    '& > div': {
      width: '100%',
    },
  },
  pdtImg: {
    maxWidth: 70,
    maxHeight: 70,
    borderRadius: 8,
    marginRight: 5,
  },
  cartDel: {
    float: 'right',
    padding: 2,
    width: 30,
    height: 30,
    fontSize: 18,
  },
  cartAmnt: {
    float: 'right',
    padding: 2,
    height: 8,
    fontSize: 13,
    marginTop: 2,
  },
  paymentLogo: {
    background: '#fff',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 6,
    margin: 8,
    borderRadius: 10,
    boxShadow: '0px 0px 5px 0px rgba(157,171,209,.4)',
    alignItems: 'center',
    '& > img': {
      margin: 'auto',
      height: 25,
    },
    '& > small': {
      marginTop: 3,
      whiteSpace: 'pre',
    },
  },
  itemActive: {
    border: '3px solid #a6a6ff',
  },
  orderSummary: {
    color: '#666',

    fontWeight: 700,
  },
}

function Orders(props) {
  console.log(props);
  // eslint-disable-next-line no-unused-expressions
  !props.auth && props.history.push('/')

  const login = localStorage.getItem('_log') != null ? JSON.parse(atob(localStorage.getItem('_log'))) : {}
  const token = localStorage.getItem('_token') != null ? JSON.parse(atob(localStorage.getItem('_token'))) : {}
  const [slider, setSlider] = React.useState(0)
  const [prefer, setPrefer] = React.useState('')
  const [bkashNum, setBkashNum] = React.useState('')
  const [paymethodMethod, setPaymentMethod] = React.useState('')
  const [tran, setTran] = React.useState('')
  const [refferal, setRefferal] = React.useState(props.referer != null ? props.referer + 1000 : '')
  const [isLoading, setLoading] = React.useState(false)
  const [checked, setChecked] = React.useState(false);
  const [cash, setCash] = React.useState(0);
  const [errors, setError] = React.useState('')

  const [state, setState] = React.useState({
    adress: token.adress != null ? token.adress : '',
    mobile: login.mob,
  });
  const maximumCashback = props.settings.cashback;

  const classes = props.classes
  let total = 0
  let totalItem = 0
  let totalRefererEarn = 0
  let totalCashback = 0
  props.cart.map(item => {
    totalRefererEarn += parseInt((item.org_price / 100) * item.referer_income)
    total += item.price * item.quan
    totalItem += item.quan
    totalCashback += item.cashback
    return true
  })
  function changePaymentMethod(e) {
    const slideId = e.currentTarget.getAttribute('data-slide_id');
    const paymentMethod = e.currentTarget.getAttribute('data-payment_method');
    // eslint-disable-next-line radix
    setSlider(parseInt(slideId))
    setPaymentMethod(paymentMethod)
    // eslint-disable-next-line no-prototype-builtins
    if (errors.hasOwnProperty('pmethod')) {
      errors['pmethod'] = ''
    }
    setError(errors)
  }
  const handleInputChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  function quanChange(e) {
    const productId = e.currentTarget.getAttribute('data-id')
    props.qtyChange({ pdtId: Number(productId), quan: Number(e.target.value) })
  }

  const cashBack = (event) => {
    const userID = Number(localStorage.getItem('s'))
    setChecked(event.target.checked)
    const totalAmnt = total + props.marcent.length * 80
    const blance = parseInt(totalAmnt * maximumCashback / 100)

    axios.get(`${ApiLink.userblance}/${userID}`).then(r => {
      const amount = Number(r.data);
      if (amount >= blance) {
        if (checked === false) {
          setCash(blance)
        } else {
          setCash(0);
        }
      } else {
        setChecked(false)
        props.snackShow('YOU DO NOT HAVE SUFFICIENT BALANCE')
        setTimeout(() => {
          props.snackClose()
        }, 2000);
      }
    })
  }

  function sendOrder(e) {
    e.preventDefault()
    setError('')
    const errors = {}
    if (!state.adress) {
      errors['adress'] = 'Please enter your delivery address'
      document.getElementById('adress').focus();
    }
    else if (!paymethodMethod) {
      errors['pmethod'] = 'Please choose your payment method'

    }
    else if (!bkashNum) {
      errors['bkash_num'] = 'Please enter your sender number'

    }
    else if (bkashNum.length !== 11) {
      errors['bkash_num'] = 'Mobile number must be 11 digit'
    }
    setError(errors)
    // eslint-disable-next-line no-unused-expressions
    if (Object.keys(errors).length === 0) {
      if (!isLoading) {
        setLoading(true)
        let adressFlag = false
        if (state.adress !== token.adress) {
          adressFlag = true
          token.adress = state.adress
          localStorage.setItem('_token', btoa(JSON.stringify(token)))
        }
        const shippingCharge = props.settings.ship_dhk * props.marcent.length;
        const userId = localStorage.getItem('s');
        const params = new URLSearchParams()
        params.append('adress', state.adress)
        params.append('user_id', userId)
        params.append('adressFlag', adressFlag)
        params.append('prefer', prefer)
        params.append('bkashNum', bkashNum)
        params.append('paymentMethod', paymethodMethod)
        params.append('mobile', state.mobile)
        params.append('ownMobile', state.mobile)
        params.append('tran', tran)
        params.append('total', total)
        params.append('totalItem', totalItem)
        params.append('totalRefererEarn', totalRefererEarn)
        params.append('ship', shippingCharge)
        params.append('refferal', refferal)
        params.append('blanceUse', cash)
        params.append('cart', JSON.stringify(props.cart))
        console.log(params);

        props.clearCart();
        props.clearMarcent()
        axios.post(ApiLink.cartOrder, params)
          .then(r => {
            if (r.data.success === 1) {
              setLoading(false)
              props.history.push('/success')

            }
          })
          .catch(() => {

          })
      }
    }
  }
  return (
    <>
      <div className={classes.wrapper}>
        <div>

          <h3 style={{ display: 'flex' }}>
            {' '}
            <ShoppingBasketOutlined />
            {' '}
          &nbsp; Products in Cart
        </h3>
          <hr style={{ margin: '9px 0', border: '.5px solid #bfbfbf' }} />

          {props.marcent.map((marcent, key) => (
            <fieldset style={{ borderRadius: 9 }}>
              <legend>
                Package
                {' '}
                {key + 1}
              </legend>
              <div style={{ padding: '8px 4px', fontWeight: 'bold' }}>
                <small>Shipping Charge : ৳ 80</small>
              </div>

              {props.cart.map(pdt => {
                if (marcent.marchant_id === pdt.marchant_id) {
                  return (
                    <div key={pdt.id} className={classes.pdtListItem}>
                      <img className={classes.pdtImg} src={`${ApiLink.img_link}/mini/min${pdt.thumb}`} alt="cart thumbnail" />
                      <div>
                        <div>
                          {pdt.name}
                          <IconButton onClick={() => props.delFromCart({ pdtId: pdt.id, marcentId: pdt.marchant_id })} className={classes.cartDel}>✖</IconButton>

                        </div>
                        <div style={{ color: 'gray' }}>
                          -
                          <i>{pdt.sub_cat}</i>
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <span className="cart-price">
                            Price:
                          {' '}
                               ৳
                          {' '}
                            {pdt.price}
                            {' '}

                            {' '}
                          </span>

                          <IconButton onClick={() => props.qtyChange({ pdtId: pdt.id, quan: pdt.quan - 1 })} id="minus" mini="true" className="pm-btn" component="button">
                            <span className="pm-icon">-</span>
                          </IconButton>
                          <input type="number" data-id={pdt.id} id="quan_inp" onChange={quanChange} value={pdt.quan} />
                          <IconButton id="plus" mini="true" onClick={() => props.qtyChange({ pdtId: pdt.id, quan: pdt.quan + 1 })} className="pm-btn" component="button">
                            <span className="pm-icon">+</span>
                          </IconButton>


                          {' '}


                          <p className={classes.cartAmnt} style={{ color: '#666', fontWeight: 700 }}>
                            ৳
                            {pdt.price * pdt.quan}
                          </p>

                        </div>


                      </div>
                    </div>

                  );
                }
              })}
            </fieldset>
          ))}

          <div className={classes.paymentSlider}>

            <div>
              <form onSubmit={sendOrder}>
                <fieldset style={{ borderRadius: 9 }}>
                  <legend>Delivery Information</legend>

                  <Grid
                    container
                    direction="row"
                    spacing={3}
                  >
                    <Grid item md={8} sm={11} xs={11}>
                      <TextField
                        id="adress"
                        name="adress"

                        label="Delivery Adress..."
                        value={state.adress}
                        // onChange={e => setAdress(e.target.value)}
                        onChange={handleInputChange}
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth

                      />
                      <br />
                      <div className={classes.error_msg} style={{ display: !errors.adress && 'none' }}>
                        {' '}
                        <ErrorOutline fontSize="small" />
                        {' '}
                            &nbsp; {errors.adress}
                      </div>
                    </Grid>
                    <Grid item md={4} sm={11} xs={11}>
                      <TextField
                        id="prefer"
                        label="Color / Size / Other Preference"
                        value={prefer}
                        onChange={e => setPrefer(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth

                      />

                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"

                  >
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        id="personal_mob"
                        name="mobile"
                        type="number"
                        className="inputField"
                        helperText="Give your contact number"
                        label="Your Mobile..."
                        value={state.mobile}
                        onChange={handleInputChange}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        id="refferal"
                        className="inputField"
                        label="Refferal Code (optional)"
                        helperText="Have any reffaral code for discount ?"
                        value={refferal}
                        onChange={e => setRefferal(e.target.value)}
                        margin="dense"
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                </fieldset>


                <fieldset style={{ overflowX: 'scroll', borderRadius: 9 }}>
                  <legend>Payment</legend>

                  <div>

                    <Grid
                      container
                      direction="row"
                    >

                      <Grid item md={9} sm={12} xs={12}>

                        {slider === 1 && (

                          <div className="success_msg">
                            <WarningRounded />
                        আপনার প্রডাক্টের পেমেন্টি&nbsp;bkash persional &nbsp;
                            {props.settings.number}
&nbsp;
                        নাম্বার এ পেমেন্ট করে নিচে Sender নাম্বার এবং TrxID নাম্বারটি বসিয়ে submit করুন
                          </div>
                        )}
                        {slider === 2 && (
                          <div className="success_msg">
                            <WarningRounded />
                        আপনার প্রডাক্টের পেমেন্টি&nbsp;bkash persional &nbsp;
                            {props.settings.number}
&nbsp;
                        নাম্বার এ পেমেন্ট করে নিচে Sender নাম্বার এবং TrxID নাম্বারটি বসিয়ে submit করুন
                          </div>
                        )}
                        {slider === 3 && (
                          <div className="success_msg">
                            <WarningRounded />
                        আপনার প্রডাক্টের পেমেন্টি&nbsp;rocket persional &nbsp;
                            {props.settings.rocket}
&nbsp;
                        নাম্বার এ পেমেন্ট করে নিচে Sender নাম্বার এবং TrxID নাম্বারটি বসিয়ে submit করুন
                          </div>
                        )}
                        {slider === 4 && (
                          <div className="success_msg">
                            <WarningRounded />
                        আপনার প্রডাক্টের পেমেন্টি&nbsp;nogod persional &nbsp;
                            {props.settings.nogod}
&nbsp;
                        নাম্বার এ পেমেন্ট করে নিচে Sender নাম্বার এবং TrxID নাম্বারটি বসিয়ে submit করুন
                          </div>
                        )}

                        <br />

                        <div>
                          {slider === 1 && (
                            <div className="success_msg">
                              <WarningRounded />
                              <div>
                                আপনার পন্যটি ক্রয় করতে ৩০০ টাকা Bkash Advance পেমেন্ট করে Sender নাম্বার ও TrxID বসিয়ে Submit করুন
                            </div>
                            </div>
                          )}
                        </div>


                        <ButtonBase onClick={changePaymentMethod} data-slide_id="1" data-payment_method="Cash" className={`${classes.paymentLogo} ${slider === 1 && (classes.itemActive)}`}>
                          <img src={cod} alt="bKash card mali-mart" />
                          <small>Cash On Delivery</small>
                        </ButtonBase>
                        <ButtonBase onClick={changePaymentMethod} data-slide_id="2" data-payment_method="Bkash" className={`${classes.paymentLogo} ${slider === 2 && (classes.itemActive)}`}>
                          <img src={bkash2} alt="bKash card mali-mart" />
                          <small>bKash</small>
                        </ButtonBase>
                        <ButtonBase onClick={changePaymentMethod} data-slide_id="3" data-payment_method="rocket" className={`${classes.paymentLogo} ${slider === 3 && (classes.itemActive)}`}>
                          <img src={rocket} alt="Rocket card mali-mart" />
                          <small>Rocket</small>
                        </ButtonBase>
                        <ButtonBase onClick={changePaymentMethod} data-slide_id="4" data-payment_method="Nogod" className={`${classes.paymentLogo} ${slider === 4 && (classes.itemActive)}`}>
                          <img src={nogod} alt="Nogod card mali-mart" />
                          <small>Nogod</small>
                        </ButtonBase>
                        <ButtonBase onClick={() => changePaymentMethod} data-slide_id="5" data-payment_method="Master" className={`${classes.paymentLogo} ${slider === 5 && (classes.itemActive)}`}>
                          <img src={master} alt="Master card mali-mart" />
                          <small>Master</small>
                        </ButtonBase>

                        <ButtonBase onClick={() => changePaymentMethod} data-slide_id="6" data-payment_method="Visa" className={`${classes.paymentLogo} ${slider === 6 && (classes.itemActive)}`}>
                          <img src={visa} alt="Visa card mali-mart" />
                          <small>Visa</small>
                        </ButtonBase>

                        <div className={classes.error_msg} style={{ display: !errors.pmethod && 'none' }}>
                          {' '}
                          <ErrorOutline fontSize="small" />
                          {' '}
                            &nbsp; {errors.pmethod}
                        </div>
                      </Grid>


                      <Grid className={classes.orderSummary} item md={3} alignItems="right" sm={12} xs={12}>
                        <h3 style={{
                          textAlign: 'center', padding: 0, color: '#B8B8B8', fontSize: 16, margin: 0,
                        }}
                        >
                          Order Summary
                        </h3>
                        <Table>
                          <tr>
                            <td>Subtotal</td>
                            <td />
                            <td>
                              ৳
                              {total}
                            </td>
                          </tr>
                          <tr>
                            <td>Mali-Mart Blance (-)	</td>
                            <td />
                            <td>
                              ৳
                              {cash}
                            </td>
                          </tr>
                          <tr>
                            <td>Delivery Charge	</td>
                            <td />
                            <td>
                              ৳
                              {props.marcent.length * 80}
                            </td>
                          </tr>
                          <hr style={{ border: '1px dashed', width: '124%' }} />
                          <tr>
                            <td>Total</td>
                            <td />
                            <td>
                              ৳
                              {total + props.marcent.length * 80 - cash}
                            </td>
                          </tr>

                        </Table>
                      </Grid>

                    </Grid>
                  </div>

                  {slider === 1 && (
                    <div>
                      <TextField
                        id="bkash_num"
                        type="number"
                        className="inputField"
                        label="bKash Number..."
                        helperText="Payment bKash number"
                        value={bkashNum}
                        onChange={e => setBkashNum(e.target.value)}
                        margin="dense"
                        variant="outlined"
                      />
                      <TextField
                        id="cus_tran"
                        className="inputField"
                        label="Transaction ID"
                        helperText="Transaction ID (TrxId)"
                        value={tran}
                        onChange={e => setTran(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                    </div>
                  )}
                  {slider === 2 && (
                    <div>
                      <TextField
                        id="bkash_num"
                        type="number"
                        className="inputField"
                        label="bKash Number..."
                        helperText="Payment Bkash number"
                        value={bkashNum}
                        onChange={e => setBkashNum(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                      <TextField
                        id="cus_tran"
                        className="inputField"
                        label="Transaction ID"
                        helperText="Transaction ID (TrxId)"
                        value={tran}
                        onChange={e => setTran(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                    </div>
                  )}
                  {slider === 3 && (

                    <div>
                      <TextField
                        id="bkash_num"
                        type="number"
                        className="inputField"
                        label="Rocket Number..."
                        helperText="Payment Rocket number"
                        value={bkashNum}
                        onChange={e => setBkashNum(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                      <TextField
                        id="cus_tran"
                        className="inputField"
                        label="Transaction ID"
                        helperText="Transaction ID (TrxId)"
                        value={tran}
                        onChange={e => setTran(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                    </div>

                  )}
                  {slider === 4 && (
                    <div>
                      <TextField
                        id="bkash_num"
                        type="number"
                        className="inputField"
                        label="Nagad Number..."
                        helperText="Payment Nagad number"
                        value={bkashNum}
                        onChange={e => setBkashNum(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                      <TextField
                        id="cus_tran"
                        className="inputField"
                        label="Transaction ID"
                        helperText="Transaction ID (TrxId)"
                        value={tran}
                        onChange={e => setTran(e.target.value)}
                        margin="dense"
                        variant="outlined"

                      />
                    </div>
                  )}
                  {slider === 5 && (
                    <div>
                      Comeing soon..
                      <br />
                Not Available Now.
                    </div>
                  )}
                  {slider === 6 && (
                    <div>
                      Comeing soon..
                      <br />
                Not Available Now.
                    </div>
                  )}
                  <div className={classes.error_msg} style={{ display: !errors.bkash_num && 'none' }}>
                    {' '}
                    <ErrorOutline fontSize="small" />
                    {' '}
                              &nbsp; {errors.bkash_num}
                  </div>

                  <div>

                    <label key={Math.random()} style={{ marginRight: '2px', fontSize: '1rem' }}>
                      <Checkbox
                        defaultChecked
                        checked={checked}
                        onChange={cashBack}
                        color="secondary"
                        inputProps={{ 'aria-label': 'Mali-Mart Balance' }}
                      />
                       Mali-Mart Balance
                    </label>

                    <br />
                    &nbsp;
                    <span style={{ color: '#f50057', fontWeight: 'normal' }}>
                      {' '}
                        You can pay up to &nbsp;
                      {maximumCashback}
                        % of the order amount buy using Balance
                    </span>
                  </div>

                </fieldset>


                <Button id="order-btn" type="submit" variant="contained" color="primary">
                  <span id="order-btn-txt"> Send Order &nbsp;</span>
                  {!isLoading ? <Send id="snd" className="btn-icn" />
                    : <CircularProgress id="load" style={{ color: 'black' }} size={20} thickness={4} />}
                </Button>
                <div id="success" className="d-non text-center">

                </div>
              </form>
            </div>

          </div>
        </div>


      </div>
    </>
  )
}


function mapStateToProps(state) {
  return {
    auth: state.auth,
    settings: state.settings,
    cart: state.cart,
    marcent: state.marcent,
    referer: state.referer,
  }
}


function mapDispatchToProps(dispatch) {
  return {
    snackShow: msg => dispatch({ type: 'snackShow', payload: msg }),
    snackClose: () => dispatch({ type: 'snackClose' }),
    delFromCart: obj => dispatch({ type: 'delFromCart', payload: obj }),
    qtyChange: obj => dispatch({ type: 'qtyChange', payload: obj }),
    clearCart: () => dispatch({ type: 'clearCart' }),
    clearMarcent: () => dispatch({ type: 'clearMarcent' }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Orders));
