import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import ApiLink from './ApiLink'


const ImgLoader = () => (
  <span>
    <ContentLoader
      height={150}
      width={141}
      speed={1}
      primaryColor="#fff"
      secondaryColor="#fafafa"
    >
      <rect x="9" y="121" rx="4" ry="4" width="112" height="7" />
      <rect x="6" y="5" rx="7" ry="7" width="131" height="110" />
      <rect x="9" y="136" rx="4" ry="4" width="43" height="8" />
    </ContentLoader>
  </span>
)
let isMounted;
export default class HomeProductThumb extends Component {
    state = {
      loading: true,
    }

    componentDidMount() {
      isMounted = true;
      const objImg = new Image();
      objImg.src = `${ApiLink.img_link}mini/min${this.props.img}`;
      objImg.onload = () => {
        if (isMounted) {
          this.setState({
            loading: false,
          });
        }
      };
    }

    componentWillUnmount() { isMounted = false }

    render() {
      if (this.state.loading) {
        return <ImgLoader />
      }

      return (
        <div className="pdt-thumb">
          <div><Link to={`/cats/product/${this.props.id}`}><img src={`${ApiLink.img_link}mini/min${this.props.img}`} alt="" /></Link></div>
          <small>{this.props.name}</small>
          <span>
            {' '}
            {this.props.price}
            {' '}
            Tk
          </span>
        </div>
      )
    }
}
