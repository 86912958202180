import React from 'react'
import '../../App.css'
import { Grid } from '@material-ui/core/'
import gooleplay from '../../images/google_play_store.png'
import app from '../../images/app_store.png'
import youtube from '../../images/Youtube.png'
import facebook from '../../images/Facebook.png'

export default function Footer() {
  return (
    <div id="footer">
      <Grid
        container
        direction="row"
        spacing={3}
      >
        <Grid item md={3} sm={11} xs={11}>
          <h3>Download</h3>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.shop.bizbazar" target="_blank"><img src={gooleplay} width="100px" /></a>
          &nbsp;&nbsp;
          <a href=""><img src={app} width="100px" /></a>
          </div>
        </Grid>
        <Grid item md={3} sm={11} xs={11}>
          <h3>Menu</h3>
          <ul style={{ listStyle: 'none', margin: '0px', padding: '0px', color: '#615e58' }}>
            <li><a href="/how-to-shop-on-othoba">Privacy Policy</a></li>
            <li><a href="/emi-policy">Purchasing Policy</a></li>
            <li><a href="/cancellation-and-returns">Terms & Condition</a></li>
          </ul>
        </Grid>
        <Grid item md={3} sm={11} xs={11}>
          <h3>Contact Us</h3>
          <div>
            <p>Email : malimartbd@gmail.com
            <br />
               Cell  : 01302-085746
            </p>
          </div>
        </Grid>
        <Grid item md={3} sm={11} xs={11}>
          <h3>Get in Touch</h3>
          <ul id="social" style={{ listStyle: 'none', margin: '0px', padding: '0px', color: '#615e58' }}>
            <li><a href="https://www.facebook.com/Mali-Mart-112011054308812">
              <img src={facebook} />&nbsp; Mali Mart Page
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/groups/4406234349391028">
                <img src={facebook} />&nbsp; Help and Support Group
                </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCX0Oh5_nnq9gq5VMmisrn0g/featured">
                <img src={youtube} />&nbsp; YouTube
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3} alignContent="center" alignItems="center">
        <Grid item md={12} sm={11} xs={11}>
          <p style={{ textAlign: 'center' }}>© All Right Reserved by Mali-Mart 2020</p>
        </Grid>
      </Grid>
    </div>
  )
}
