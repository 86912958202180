import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import SubCategoryThumb from './SubCategoryThumb'
import ProductList from './ProductList'

const styles = () => ({
  subList_wrp: {
    overflowX: 'scroll',

  },
  subList: {
    display: 'inline-flex',
    justifyContent: 'center',
    flexDirection: 'row',
    minWidth: '100%',

  },
})

function CategoryList(props) {
  return (
    <>
      <div className={props.classes.subList_wrp}>
        <div className={props.classes.subList}>
          <SubCategoryThumb cats={props.child} catIcon={props.catIcon} />
        </div>
      </div>
      <br />
      <ProductList cat_url={props.cat_url} cat_id={props.cat_id} cat_name={props.cat_name} />
    </>
  )
}


CategoryList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CategoryList)
