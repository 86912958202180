import React, { Component } from 'react'
import Axios from 'axios'
import '../App.css'
import { Grid, IconButton, Button } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import Send from '@material-ui/icons/Send'
import Lin from '@material-ui/icons/Link'
import { ShoppingCart, MonetizationOn } from '@material-ui/icons'
import Close from '@material-ui/icons/Close'
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined'
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined'
import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import copy from 'copy-to-clipboard'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import replace from '../images/change.svg'
import deliveryTruck from '../images/delivery-truck.svg'
import call from '../images/call.svg'
import master from '../images/master.png'
import rocket from '../images/rocket.png'
import nogod from '../images/nogod.png'
import visa from '../images/visa.png'
import bkash2 from '../images/bkash2.png'
import { ImgLoader } from './Loader'
import bkash from '../images/bkash.png'
import ApiLink from './ApiLink'

const PdtDetailsLoaderDesk = () => (
  <ContentLoader
    height={500}
    width={1300}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="20" y="15" rx="20" ry="20" width="300" height="320" />
    <rect x="92" y="347" rx="5" ry="5" width="45" height="45" />
    <rect x="148" y="347" rx="5" ry="5" width="45" height="45" />
    <rect x="205" y="347" rx="5" ry="5" width="45" height="45" />
    <rect x="361" y="17" rx="10" ry="10" width="420" height="33" />
    <rect x="361" y="71" rx="10" ry="10" width="315" height="33" />
    <rect x="361" y="125" rx="10" ry="10" width="233" height="20" />
    <rect x="361" y="216" rx="5" ry="5" width="195" height="13" />
    <rect x="361" y="251" rx="5" ry="5" width="195" height="13" />
    <rect x="367" y="311" rx="8" ry="8" width="130" height="38" />
    <rect x="515" y="311" rx="8" ry="8" width="130" height="38" />
  </ContentLoader>
)

const PdtDetailsLoaderMob = () => (
  <ContentLoader
    height={400}
    width={340}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="42" y="15" rx="20" ry="20" width="265" height="250" />
    <rect x="103" y="275" rx="5" ry="5" width="30" height="30" />
    <rect x="156" y="276" rx="5" ry="5" width="30" height="30" />
    <rect x="205" y="276" rx="5" ry="5" width="30" height="30" />
    <rect x="77" y="330" rx="5" ry="5" width="193" height="15" />
    <rect x="113" y="354" rx="5" ry="5" width="116" height="15" />
  </ContentLoader>
)

const Thumnails = (obj) => obj.imgs.map((img) => <img key={Math.random()} onMouseEnter={obj.thumbHover} data-img={ApiLink.img_link + img} src={`${ApiLink.img_link}thumbnail/tmb${img}`} alt="product thumbnail" />)

class ProductDetails extends Component {
  state = {
    product: null,
    loading: true,
    quan_val: 1,
    ImgLoaderDisplay: 'flex',
    orderMenuHeight: 0,
    bottom: false,
    adress: '',
    prefer: '',
    personal_mob: '',
    bkash_num: '',
    cus_tran: '',
    ship: '80',
    value: 0,
    size: '',
    color: '',

  };


  mounted;

  constructor(props) {
    super(props)
    if (props.match.params.referer != null) {
      props.setReferer(props.match.params.referer - 1000)

      /* Axios.post(`${ApiLink.resource_link}/api/set_referer/${this.props.match.params.referer}`)
        .then(() => {
          // console.log(e);
        })
        .catch(err => {
          console.log(err);
        }) */
    } else {
      /* Axios.get(`${ApiLink.resource_link}/api/get_referer`)
        .then(() => {
          // console.log(e);
        }) */
    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    Axios.get(ApiLink.product_link + this.props.match.params.id).then(rs => {
      this.mounted = true;
      if (rs.data.length > 0 && this.mounted) {
        console.log("Product Details : " + rs.data[0]);
        this.setState({
          product: rs.data[0],
          thumb_img: ApiLink.img_link + rs.data[0].thumb,
          loading: false,
        });
        const objImg = new Image();
        objImg.src = ApiLink.img_link + rs.data[0].thumb;
        objImg.onload = () => {
          this.setState({
            ImgLoaderDisplay: 'none',
          })
        }
      } else {
        this.setState({
          product: null,
          loading: false,
        });
      }
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  quanPlus = () => {
    this.setState(prevState => ({ quan_val: Number(prevState.quan_val) + 1 }))
  }

  quanMinus = () => {
    this.setState(prevState => Number(prevState.quan_val) > 1 && { quan_val: Number(prevState.quan_val) - 1 })
  }

  quanChange = (e) => {
    this.setState({
      quan_val: e.target.value,
    })
  }

  thumbHover = (e) => {
    this.setState({
      ImgLoaderDisplay: 'flex',
    })
    const Img = new Image();
    Img.src = e.target.getAttribute('data-img')
    Img.onload = () => {
      this.setState({
        thumb_img: Img.src,
        ImgLoaderDisplay: 'none',
      })
    }
  }

  imgZoom = () => {
    const img = document.getElementById('pdt-img');
    const result = document.getElementById('zoom-img');
    result.style.display = 'block'
    const lens = document.getElementById('lens')
    lens.style.display = 'block'
    const cx = result.offsetWidth / lens.offsetWidth;
    const cy = result.offsetHeight / lens.offsetHeight;
    result.style.backgroundImage = `url('${img.src}')`;
    result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`

    function getCursorPos(e) {
      let x = 0; let y = 0;

      // eslint-disable-next-line no-param-reassign
      e = e || window.event;
      /* get the x and y positions of the image: */
      const a = img.getBoundingClientRect();
      /* calculate the cursor's x and y coordinates, relative to the image: */
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /* consider any page scrolling: */
      x -= window.pageXOffset;
      y -= window.pageYOffset;
      return { x, y };
    }
    function moveLens(e) {
      /* let pos; */ let x; let y;
      e.preventDefault();
      const pos = getCursorPos(e);
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      lens.style.left = `${x}px`
      lens.style.top = `${y}px`
      result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`
    }

    lens.addEventListener('mousemove', moveLens);
    img.addEventListener('mousemove', moveLens);
    /* and also for touch screens: */
    lens.addEventListener('touchmove', moveLens);
    img.addEventListener('touchmove', moveLens);
  }

  imgZoomOut = () => {
    document.getElementById('zoom-img').style.display = 'none'
    document.getElementById('lens').style.display = 'none'
  }

  toggleDrawer = (side, open) => () => {
    if (this.props.auth) {
      this.setState({
        [side]: open,
      });
    } else {
      this.props.setLoginModal(true)
    }
  };

  orderMenu = () => {
    this.setState(oldState => ({ orderMenuHeight: oldState.orderMenuHeight === 100 ? 0 : 100 }))
  }

  inputChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  radioChange = (e) => {
    this.setState({
      ship: e.target.value,
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  sizeChange = e => {

    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  colorChange = e => {

    this.setState({
      [e.target.name]: e.target.value,
    })

  }

  copyLink = pram => () => {
    const link = `https://mali-mart.com/cats/product/${pram}/${this.props.referer}`
    copy(link)
    this.props.snackShow('Copied.')
    setTimeout(() => {
      this.props.snackClose()
    }, 2000);
    // this.setState({ anchorEl: null });
  }

  order = (e) => {
    e.preventDefault()

    const pdtCode = document.getElementById('pdt-code').innerHTML
    const totalPrice = document.getElementById('totalPrice').innerHTML
    document.getElementById('order-btn-txt').innerHTML = 'Sending... '
    document.getElementById('snd').classList.add('d-non')
    document.getElementById('load').classList.remove('d-non')
    const deta = {
      adrs: this.state.adress,
      prefer: this.state.prefer,
      personal_mob: this.state.personal_mob,
      bkash_num: this.state.bkash_num,
      tran_id: this.state.cus_tran,
      quan: this.state.quan_val,
      pdt_code: pdtCode,
      referer_id: this.props.referer,
      referer_income: this.state.product.referer_income,
      ship: this.state.ship,
      total_price: Number(totalPrice),

    }

    Axios({
      method: 'post',
      url: ApiLink.send_order,
      data: deta,
    })
      .then(r => {
        if (r.data === 'success') {
          document.getElementById('order-btn').classList.add('d-non')
          document.getElementById('sm').classList.remove('d-non')
        }
      })
      .catch(() => {
        document.getElementById('order-btn-txt').innerHTML = 'Send Order '
        document.getElementById('snd').classList.remove('d-non')
        document.getElementById('load').classList.add('d-non')
      })
  }

  addItemToCart(pdt, quan, ship, marchant_id, buyNow = false) {
    const newPdt = {
      id: Number(pdt.id), cashback: pdt.cashback, org_price: pdt.o_price, product_size: this.state.size, product_color: this.state.color, marchant_id: pdt.own_refer_id, name: pdt.name, referer_income: pdt.referer_income, sub_cat: pdt.sub_cat_name, thumb: pdt.thumb, price: pdt.price - pdt.discount, quan, ship,
    }
    const existPdt = { id: Number(pdt.id), quan }
    const cartItem = this.props.cart;
    const check = cartItem.filter((item) => item.id === newPdt.id)[0];
    if (check) {
      this.props.updateCart(existPdt)
    } else {
      this.props.insertCart(newPdt)
    }
    const marcent = { marchant_id: pdt.own_refer_id }
    const marcentCheck = this.props.marcent.filter((item) => item.marchant_id === pdt.own_refer_id)[0];

    if (!marcentCheck) {
      this.props.marcentAdd(marcent);
    }

    this.props.snackShow('Added in Cart')
    setTimeout(() => {
      this.props.snackClose()
    }, 2000);
    if (buyNow) this.props.history.push('/order')
  }

  render() {
    const { state } = this;
    let colors = null;
    let sizes = null;
    if (this.state.loading) {
      return window.innerWidth < 950 ? <PdtDetailsLoaderMob /> : <PdtDetailsLoaderDesk />;
    }
    if (state.product !== null) {
      const imgs = state.product.pictures.replace(/\[|]|"/g, '').split(',');

      if (state.product.product_colors != "null") {
        const parse_colors = JSON.parse(state.product.product_colors)
        colors = parse_colors.map((parse_colors, index) => {
          return <label key={Math.random()} style={{ marginRight: '2px', fontSize: '1.2rem' }}>
            <input key={Math.random()} className="secondary" name="color" type="radio" checked={this.state.color === parse_colors} value={parse_colors} onClick={this.colorChange} />  {parse_colors}  </label>
        });
      } else {
        colors = 'No Color';
      }

      if (state.product.product_sizes != "null") {
        const parse_sizes = JSON.parse(state.product.product_sizes);


        sizes = parse_sizes.map((parse_sizes, index) => {
          return <label key={Math.random()} style={{ marginRight: '2px', fontSize: '1.2rem' }}>
            <input key={Math.random()} checked={this.state.size === parse_sizes} name='size' value={parse_sizes} onClick={this.sizeChange} type="radio" /> {parse_sizes} </label>
        });
      } else {
        sizes = 'No Sizes';
      }
      return (
        <>
          <Grid container>
            <Grid container justify="center">
              <Grid item md={4} xs={12} style={{ marginTop: '20px' }}>
                <Grid container justify="center">
                  <Grid item>
                    <div className="img-wrapper">
                      <ImgLoader display={state.ImgLoaderDisplay} />
                      <div onMouseOut={this.imgZoomOut} onBlur={this.imgZoomOut} id="lens" className="img-zoom-lens" />
                      <img
                        id="pdt-img"
                        className="product_detail_img"
                        src={state.thumb_img}
                        alt="product-thumbnail"
                        onMouseMove={this.imgZoom}
                      />
                    </div>
                    <div id="zoom-img" onMouseEnter={this.imgZoomOut} />
                  </Grid>
                </Grid>
                <Grid item className="product_detail_img_thumb">
                  <Thumnails imgs={imgs} thumbHover={this.thumbHover} />
                </Grid>
              </Grid>
              <Grid item md={8} sm={11} xs={11} className="pdt-info">
                <h2 className="pdt-title">{state.product.name}</h2>
                <div className="mb5 pdt-company">
                  -by
                    {' '}
                  <span style={{ fontWeight: 450 }}>{state.product.company}</span>
                </div>
                <div>
                  <b>Color :</b>
                  {colors}
                </div>

                <div>
                  <b> Size : </b>
                  {sizes}
                </div>

                <div>
                  <b>Product Code:</b>
                  {' '}
                  {state.product.product_code}
                </div>
                <br />
                <div>
                  <b>Product Price :</b>
                  {' '}
                  <del>{state.product.price}</del>
                  {' '}
                    Tk
                    {' '}
                </div>
                <div className="mb10">
                  <b> Discount Price :</b>
                  {' '}
                  <h2 className="price_tag">
                    {state.product.price - state.product.discount}
                    {' '}
                      Tk
                    </h2>
                </div>


                <div className="">
                  <b> Delivery Duration :</b>
                  {' '}
                  <h2 className="price_tag">
                    {state.product.delivery}
                    {' '}
                      Day
                    </h2>
                </div>
                <div className="mb10">
                  {/* <Radio
                      id="ctg_inside"
                      checked={this.state.ship === '40'}
                      onChange={this.radioChange}
                      value="40"
                      color="primary"
                      style={{ padding: 0 }}
                    />
                    <label htmlFor="ctg_inside"><small> Shipping : <b><span style={{ fontSize: 18 }}>৳</span> {this.props.ship_ctg} tk</b> (Inside Chittagong)</small></label>
                    <br></br> */}
                  <Radio
                    id="ctg_outside"
                    checked={this.state.ship === '80'}
                    onChange={this.radioChange}
                    value="80"
                    color="primary"
                    style={{ padding: 0 }}
                  />
                  <label htmlFor="ctg_outside">
                    <small>
                      {' '}
                        Shipping :
                        {' '}
                      <b>
                        <span style={{ fontSize: 18 }}>৳</span>
                        {' '}
                        {this.props.ship_dhk}
                        {' '}
                          tk
                        </b>
                      {' '}
                    </small>

                  </label>
                </div>
                <div>
                  <b> Category :</b>
                  {' '}
                  <Link to={`/${state.product.category_name.replace(/\s/g, '')}`}><b>{state.product.category_name}</b></Link>
                  {' '}
                  {state.product.sub_cat_name != null && <Link to={`/${state.product.sub_cat_name.replace(/\s/g, '')}`}><b>{` / ${state.product.sub_cat_name}`}</b></Link>}
                </div>
                <div id="quantity">
                  <b>Quantity:</b>
                  <IconButton onClick={this.quanMinus} id="minus" mini="true" className="pm-btn" component="button">
                    <span className="pm-icon">-</span>
                  </IconButton>
                  <input type="number" value={state.quan_val} onChange={this.quanChange} id="quan_inp" />
                  <IconButton onClick={this.quanPlus} id="plus" mini="true" className="pm-btn" component="button">
                    <span className="pm-icon">+</span>
                  </IconButton>
                </div>

                {state.product.coupon_type === 1 && state.product.cashback !== ''
                  ? (
                    <Button
                      style={{
                        backgroundColor: '#f50057', minWidth: '30.5%', padding: '25px 27px', color: 'black', marginTop: '11px', fontWeight: 'bold',
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      {' '}
                      <MonetizationOn />
                      {' '}
                      {`${state.product.cashback}% CASHBACK`}
                    </Button>
                  )
                  : ''}

                <div>
                  <br />
                  <Button onClick={() => this.addItemToCart(this.state.product, this.state.quan_val, this.state.ship, true)} variant="contained" className="buy-now-btn">
                    Buy Now
                      {' '}
                    <ShoppingBasket className="btn-icn" />
                    {' '}
                  </Button>
                  <Button onClick={() => this.addItemToCart(this.state.product, this.state.quan_val, this.state.ship)} variant="contained" className="basket-btn">
                    {' '}
                      Add to Basket
                      {' '}
                    <ShoppingCart className="btn-icn" />
                    {' '}
                  </Button>
                  {this.props.referer != null && (
                    <Button onClick={this.copyLink(state.product.id)} variant="contained" className="cpy-btn">
                      {' '}
                          Copy Affiliate Link
                      {' '}
                      <Lin className="btn-icn" />
                      {' '}
                    </Button>
                  )}
                </div>
                <div className="features-wrp">
                  <div className="features">

                    <div>
                      <img src={replace} alt="Quick-Trade Product Repalce" />
                        Replacement Available
                      </div>
                    <div>
                      <img src={deliveryTruck} alt="Quick-Trade urgent delivery" />
                        DELIVERED BY
                        {' '}
                      <br />
                        Usually in 1-5 days
                      </div>
                    <div>
                      <img src={call} alt="Quick-Trade order by Call" />
                        Instant Call to Order
                        {' '}
                      <br />

                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="paym">
            <b>Payment Method : </b>
            <br />
            <img src={bkash2} alt="pay method" />
            <img src={rocket} alt="pay method" />
            <img src={nogod} alt="pay method" />
            <img src={master} alt="pay method" />
            <img src={visa} alt="pay method" />
          </div>
          <Grid container justify="center">
            <Grid item md={11} className="description">
              <Tabs
                value={state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab className="tab" icon={<DescriptionOutlined />} label=" Description" />
                <Tab className="tab" icon={<LocalShippingOutlined />} label="PURCHASE & DELIVERY" />
                <Tab className="tab" icon={<ExitToAppOutlined />} label="REPLACE POLICY" />
              </Tabs>

              { /* eslint-disable-next-line react/no-danger */}
              {state.value === 0 && <div className="tabs" dangerouslySetInnerHTML={{ __html: state.product.desc }} />}
              {state.value === 0 && state.product.spec != null && state.product.spec !== '' && (
                <div className="tabs">
                  {' '}
                  <br />
                  <b>Specification :</b>
                  {' '}
                  <br />
                  {' '}
                  {/*  eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: state.product.spec }} />
                </div>
              )}

              {state.value === 1 && (
                <div className="tabs">
                  <b>Purchase Step :</b>
                  <ul>
                    <li>Select number of product you want to buy.</li>
                    <li>Click on Buy Now button.</li>
                    <li>Follow required instruction based on payment method.</li>
                    <li>Order confirmation and delivery completion are subject to product availability.</li>
                    <li>Once sold, product cannot be returned & replaced until it has warranty.</li>
                    <li>Please check your product at the time of delivery.</li>
                  </ul>
                      ◼ Disclaimer: Product color may slightly vary due to photography, lighting sources or your monitor settings.
                  <br />
                  <br />
                  <b>How to pay:</b>
                  <ul>
                    <li>Cash on Delivery</li>
                    <li>Mobile Payment: bKash, SureCash, Rocket</li>
                    <li>Card - Visa, Master, Amex, Nexus, Online Banking</li>
                    <li>Payza</li>
                    <li>EMI</li>
                    <li>Nexus Pay</li>
                  </ul>

                  <br />
                  <b>Cash on Delivery Zone:</b>
                  <br />
                      ◼ Dhaka City ◼ Gazipur ◼ Savar ◼ Narayanganj ◼ Keraniganj
                  <br />
                  <br />
                      ⬛ Conditional Cash on Delivery Zone: Pay 100 tk in advance for conditional delivery and get home delivery and pay rest amount in cash on delivery.
                  <br />
                  <br />
                      ◼ Chittagong ◼ Sylhet ◼ Rajshahi ◼ Comilla ◼ Cox&apos;s Bazar ◼ Feni ◼ Bogra ◼ Noakhali ◼ Chandpur ◼ Sirajganj
                  <br />
                  <br />
                      ⬛*** Rest delivery location, advance payment is required.
                  <br />
                  <br />
                      ⬛You can make your purchases on Mali-Mart and get delivery from anywhere in the world. Delivery charge varies according to customer&apos;s
                      country. In case of paid order, PriyoShop.com cannot be held liable if customer does not receive it within 2 months.
                </div>
              )}

              {state.value === 2 && (
                <div className="tabs">
                  ◼ Product will be replaced if it has any defect by its manufacturer.
                  <br />
                      ◼ Custome needs to inform us within 24 hours from the date of delivery.
                  <br />
                      ◼ Products must be with the tags intact and in their original packaging, in an unwashed and undamaged condition.
                  <br />
                      ◼ Replacement for products is subject to inspection and checking by Mali-Mart team.
                  <br />
                      ◼ Replacement cannot be possible if the product is burnt, damaged by short circuit, damaged due to neglect, improper usage, or broken by customer.
                  <br />
                      ◼ Innerwear and product of clearance sale cannot be replaced.
                </div>
              )}
            </Grid>

          </Grid>

          <Dialog
            open={this.state.bottom}
            onClose={this.toggleDrawer('bottom', false)}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="buy-modal-title">Buy This Product</DialogTitle>
            <DialogContent>
              <div style={{ padding: 0 }}>
                <IconButton
                  onClick={this.toggleDrawer('bottom', false)}
                  color="secondary"
                  style={{
                    padding: 4, position: 'absolute', right: 7, top: 5,
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
                <div className="order-img-wrapper">
                  <img className="order-img" src={state.thumb_img} alt="product thumbnail" />
                  <div>
                    <div><b>{state.product.name}</b></div>
                    <small>
                      Product Code :
                        {' '}
                      <span id="pdt-code">{state.product.product_code}</span>
                    </small>
                    <br />
                    <small>
                      Quantity :
                        {' '}
                      {state.quan_val}
                    </small>
                    <br />
                    <p>
                      {' '}
                        Price:
                        {' '}
                      <b id="totalPrice">{((state.product.price - state.product.discount) * Number(state.quan_val)) + Number(state.ship)}</b>
                        Tk
                        {' '}
                      <small> (with Shiping)</small>

                    </p>
                    <div className="pm">
                      <b>Payment Method:</b>
                      <img src={bkash} alt="bkash" />
                    </div>
                  </div>

                  <br />
                </div>

                <div className="text-center mt10">
                  Dear Customer, Please Send
                    {' '}
                  <u>Product Price</u>
                  {' '}
                    using bKash in this number
                    {' '}
                  <b>
                    0
                      {this.props.bkash_number}
                    {' '}
                      (
                      {this.props.bkash_type}
                      )
                      {' '}
                  </b>
                  {' '}
                </div>

                <form onSubmit={this.order} className="text-center" id="form">

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item md={8} sm={11} xs={11}>
                      <TextField
                        id="adress"
                        label="Delivery Adress..."
                        value={this.state.adress}
                        onChange={this.inputChange}
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item md={4} sm={11} xs={11}>
                      <TextField
                        id="prefer"
                        label="Color / Size / Other Preference"
                        value={this.state.prefer}
                        onChange={this.inputChange}
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        required={false}
                      />
                    </Grid>
                  </Grid>


                  <TextField
                    id="personal_mob"
                    type="number"
                    className="inputField"
                    helperText="Give your contact number"
                    label="Your Mobile..."
                    value={this.state.personal_mob}
                    onChange={this.inputChange}
                    margin="dense"
                    variant="outlined"
                    required
                  />
                  <TextField
                    id="bkash_num"
                    type="number"
                    className="inputField"
                    label="bKash Number..."
                    helperText="Payment bKash number"
                    value={this.state.bkash_num}
                    onChange={this.inputChange}
                    margin="dense"
                    variant="outlined"
                    required
                  />
                  <TextField
                    id="cus_tran"
                    className="inputField"
                    label="Transaction ID"
                    helperText="Transaction ID (TrxId)"
                    value={this.state.cus_tran}
                    onChange={this.inputChange}
                    margin="dense"
                    variant="outlined"
                    required
                  />

                  <Button id="order-btn" type="submit" variant="contained" color="primary">
                    <span id="order-btn-txt"> Send Order </span>
                    <Send id="snd" className="btn-icn" />
                    <CircularProgress id="load" className="d-non" style={{ color: 'black' }} size={20} thickness={4} />
                  </Button>
                  <div id="sm" className="d-non">
                    Order Complete Successfully ✔
                      <br />
                    {' '}
                      You Will get update about delivery process.Delivary Time 3-4 Working Days
                    </div>
                </form>
                <div />
              </div>
            </DialogContent>
            <DialogActions />
          </Dialog>

        </>
      );
    }

    return <h1>No data</h1>
  }
}

function mapStateToProps(state) {
  return {
    ship_ctg: state.settings != null && state.settings.ship_ctg,
    ship_dhk: state.settings != null && state.settings.ship_dhk,
    bkash_number: state.settings != null && state.settings.number,
    bkash_type: state.settings != null && state.settings.payment_type,
    snackOpen: state.snackOpen,
    snackMsg: state.snackMsg,
    referer: state.referer,
    cart: state.cart,
    marcent: state.marcent,
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => ({
  snackShow: msg => dispatch({ type: 'snackShow', payload: msg }),
  snackClose: () => dispatch({ type: 'snackClose' }),
  setReferer: val => { dispatch({ type: 'setReferer', payload: val }) },
  insertCart: pdt => dispatch({ type: 'addToCart', payload: pdt }),
  updateCart: oldPdt => dispatch({ type: 'updateCart', payload: oldPdt }),
  marcentAdd: marcent => dispatch({ type: 'marcentAdd', payload: marcent }),
  setLoginModal: isLoginModal => dispatch({ type: 'setLoginModal', payload: isLoginModal }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetails));
