import React from 'react'
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import TopContainer from './components/layout/TopContainer'
import store from './store'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffe000',
      shade: 'sdf',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
  shape: {
    borderRadius: 8,
  },
  zIndex: {
    appBar: 9999,
  },

});
console.log(theme);

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <TopContainer />
      </MuiThemeProvider>
    </Provider>
  )
}


export default App;
