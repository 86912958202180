import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ApiLink from './ApiLink'
import { Loader } from './Loader'

const styles = () => ({
  masonry: {
    columns: 5,
    textAlign: 'center',
    columnGap: 1,
  },
  cashBtn: {
    padding: '4px 6px',
    marginLeft: '46px',
    background: 'rgb(195, 35, 42)',
    color: 'rgb(255, 255, 255)',
    marginTop: '10px !important',
    marginBottom: '10px !important',
  },
});


// eslint-disable-next-line react/jsx-filename-extension
class CashhbackProduct extends React.Component {
  state = {
    products: [],
  };

  _mounted = true;

  componentDidMount() {
    this._mounted = true;
    axios.get(ApiLink.cashback).then(r => {
      if (r.data.length > 0 && this._mounted) {
        if (this._mounted) {
          this.setState({
            products: r.data,
          });
        }
      } else if (this._mounted) {
        this.setState({
          products: null,
        });
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const state = this.state;
    if (state.products == null) {
      return <h4> No Product Found</h4>;
    } if (state.products.length > 0) {
      return (

        <div className="flex-wrp">
            {
            state.products.map((product) => (
              <div key={product.id} className="pdt-thumb">
                <div><Link to={`/cats/product/${product.id}`}><img src={`${ApiLink.img_link}mini/min${product.thumb}`} alt="" /></Link></div>
                <small>{product.name}</small>
                <span>

                  <Button size="small" style={{padding: '4px 6px',marginTop:'5px',marginBottom:'5px'}} variant="contained" color="secondary">{`${product.cashback}% CASHBACK`}</Button>
                </span>
              </div>
            ))
          }
        </div>

      );
    }
    return <Loader />;
  }
}

CashhbackProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CashhbackProduct);
