/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
/* import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import Lin from "@material-ui/icons/Link";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem"; */
import Button from '@material-ui/core/Button';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ButtonBase from '@material-ui/core/ButtonBase';
import copy from 'copy-to-clipboard';
import apiLink from './ApiLink';

const ImgLoader = () => (
  <ContentLoader
    height={250}
    width={236}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="7" y="4" rx="10" ry="10" width="225" height="204" />
    <rect x="6" y="213" rx="8" ry="10" width="80" height="34" />
    <circle cx="214" cy="228" r="15" />
    <circle cx="176" cy="228" r="15" />
  </ContentLoader>
);


const styles = theme => ({
  thumb: {
    padding: 8,
    borderRadius: 10,
    transition: 'all .1s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      padding: 3,
    },
  },
  pdt_img: {
    width: 236,
    '&:hover': {
      opacity: 0.8,
    },
    [theme.breakpoints.down('sm')]: {
      width: 145,
    },
  },
  pdt_name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    color: '#776800',
    fontWeight: 600,
    top: 3,
    left: 5,
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },

  },
  effect: {
    width: 236,
    marginBottom: -4,
    borderRadius: 4,
    transition: 'box-shadow ease-out .2s',
    '&:hover': {
      background: '#f6f6f6  ',
      boxShadow: '0px 0px 0px 9px #f6f6f6',
      transition: 'box-shadow ease-out .2s',
    },
    '&::after': {
      content: '',
      clear: 'both',
      display: 'table',
    },
    [theme.breakpoints.down('sm')]: {
      width: 145,
    },
  },
  infoBtn: {
    padding: 2,
    float: 'right',
    marginRight: 2,
    top: 9,
  },
  priceBtn: {
    boxShadow: 'none',
    top: 4,
    left: 5,
    padding: '3px 13px',

    [theme.breakpoints.down('sm')]: {
      padding: '0px 3px',
      fontSize: 12,

    },
  },
  cartBtn: {
    boxShadow: 'none',
    top: 4,
    left: 87,

    padding: '0px 13px',

    [theme.breakpoints.down('sm')]: {
      padding: '0px 3px',
      fontSize: 12,
      left: 12,

    },
  },
  cart: {
    [theme.breakpoints.down('sm')]: {

      fontSize: '14px',


    },

  },
  MenuItem: {
    paddingLeft: 9,
    paddingRight: 9,
    '& > small': {
      display: 'flex',
    },
  },
  tmbWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    overflow: 'hidden',
    paddingBottom: 8,
  },
});

class ProductThumb extends React.Component {
  // eslint-disable-next-line
  
  static _isMounted = true

  state = {
    // anchorEl: null,
    loading: true,
  }

  objImg = new Image()

  addItemToCart() {

   
    let pdt = this.props.pdt;
    const newPdt = {
      id: Number(pdt.id),cashback: null,org_price: pdt.o_price,marchant_id:pdt.own_refer_id, name: pdt.name, referer_income: 5, sub_cat: pdt.sub_cat_name, thumb: pdt.thumb, price: pdt.d_price,quan:1,ship:pdt.ship_dhk,
    }
    const existPdt={
      id: Number(pdt.id),quan:1,
    }
     const cartItem  = this.props.cart;
     const check = cartItem.filter( (item) => item.id===newPdt.id)[0];
     if(check)
     {
       
      let item=this.props.updateCart(existPdt)
      
     }else{

    this.props.insertCart(newPdt)
     }
    const marcent = { marchant_id: pdt.own_refer_id }
    const marcentCheck = this.props.marcent.filter((item) => item.marchant_id === pdt.own_refer_id)[0];

    if (!marcentCheck) {
      this.props.marcentAdd(marcent);
    }

    this.props.snackShow('Added in Cart')
    setTimeout(() => {
      this.props.snackClose()
    }, 2000);
	
   
    console.log(this.props.pdt);

  }


  componentDidMount() {
    this._isMounted = true
    this.objImg.src = apiLink.img_link + this.props.img;
    this.objImg.onload = () => {
      if (this._isMounted) {
        this.setState({
          loading: false,
        });
      }
    };
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /*   handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    }; */

  copyLink = pram => ({
    catURL, referer, snackShow, snackClose,
  }) => {
    const link = `https:quick-trade.net/${catURL}/product/${pram}/${referer}`
    copy(link)
    snackShow('Copied.')
    setTimeout(() => {
      snackClose()
    }, 2000);
    // this.setState({ anchorEl: null });
  }

  render() {
    const prop = this.props;
    console.log(prop.classes.thumb);
    return (
      <div>
        <div className={prop.classes.thumb}>
          <div className={prop.classes.effect}>
            {this.state.loading ? <ImgLoader />
              : (
                <div className={prop.classes.tmbWrapper}>
                  <ButtonBase
                    style={{ borderRadius: 10, cursor: 'zoom-in' }}
                    component={Link}
                    to={`/${prop.catURL}/product/${prop.pdt_id}/`}
                  >
                    <img
                      className={prop.classes.pdt_img}
                      src={this.objImg.src}
                      alt="Product Thumbnail"
                    />
                  </ButtonBase>
                  <div className={prop.classes.pdt_name}>{prop.pdt_name}</div>
                  <Button
                    component={Link}
                    to={`/${prop.catURL}/product/${prop.pdt_id}/`}
                    variant="contained"
                    className={prop.classes.priceBtn}
                    color="primary"
                  >
                    <b>
                      ৳
                      {' '}
                      {prop.pdt_price}
                    </b>
                  </Button>

                  <Button


                    variant="contained"
                    className={prop.classes.cartBtn}
                    color="secondary"
                    onClick={() => this.addItemToCart()}

                  >
                    <b style={{ marginTop: '4px' }}>
                      <ShoppingCart className={prop.classes.cart} style={{ fontSize: "20px" }}></ShoppingCart>
                    </b>
                  </Button>



                  {/* <IconButton
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    mini="true"
                    className={prop.classes.infoBtn}
                    aria-label="More Info"
                  >
                    <MoreVert />
                  </IconButton>

                  <IconButton
                    mini="true"
                    className={prop.classes.infoBtn}
                    aria-label="View"
                  >
                    <VisibilityOutlined />
                  </IconButton>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    {this.props.referer != null && <MenuItem onClick={this.copyLink(prop.pdt_id)} className={prop.classes.MenuItem}><small><Lin /> <span>Copy Affiliate Link</span></small></MenuItem>}
                    <MenuItem onClick={this.handleClose}>Option 2</MenuItem>
                    <MenuItem onClick={this.handleClose}>Option 3</MenuItem>
                  </Menu> */}
                </div>
              )}
          </div>
        </div>

      </div>
    );
  }
}

ProductThumb.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  referer: state.referer,
  cart: state.cart,
  marcent: state.marcent,
})

const mapDispatchToProps = (dispatch) => ({
  snackShow: msg => dispatch({ type: 'snackShow', payload: msg }),
  snackClose: () => dispatch({ type: 'snackClose' }),
  insertCart: pdt => dispatch({ type: 'addToCart', payload: pdt }),
  updateCart: oldPdt => dispatch({ type: 'updateCart', payload: oldPdt }),
  marcentAdd: marcent => dispatch({ type: 'marcentAdd', payload: marcent }),

})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductThumb)),
);
