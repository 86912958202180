import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar, Toolbar, IconButton, Button, InputBase, SwipeableDrawer,Badge,
  ListItem, List, ListItemText, TextField, CircularProgress, Paper, ClickAwayListener, ButtonBase,
} from '@material-ui/core/'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import {
  AccountCircle, Help, ShoppingCart, ShoppingBasketOutlined,
  ChevronRightRounded, ChevronLeftRounded, ErrorOutline, ExitToApp, Dashboard, AccountCircleOutlined,
} from '@material-ui/icons/'
import SearchIcon from '@material-ui/icons/Search'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import axios from 'axios'
import txtLogo from '../../bazar.png'
import logo from '../../biz.png'
import ApiLink from '../ApiLink'
import '../../App.css'

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 39,
    top: 1,
    border: `2px solid white`,
    padding: '0 4px',
    fontSize:10,
  },
}))(Badge);

const styles = theme => ({
  root: {
    width: '100%',
  },
  logo: {
    cursor: 'pointer',
    width: 35,
    marginRight: 14,
  },
  txtLogo: {
    cursor: 'pointer',
    height: 24,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  appbar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 15,
    [theme.breakpoints.down('md')]: {
      marginRight: 7,
    },
  },
  title: {
    cursor: 'pointer',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.1),
    '&:hover': {
      boxShadow: theme.shadows[1],
      backgroundColor: fade(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchBtn: {
    position: 'absolute',
    padding: '5px',
    marginLeft: '-48px',
  },
  searchIcon: {
    width: theme.spacing(19),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      color: 'red',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('width'),
    '&::placeholder': {
      color: theme.palette.common.black,
      fontStyle: 'bold',
      opacity: '0.8',
    },
    width: '90%',
    '&:focus': {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
      '&:focus': {
        width: 570,
      },
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  cardMiddle:{
    padding: 2, 
   position: 'fixed', 
    zIndex: 10, 
    background: '#ffe000',
    top:315, 
    right:0,
    border:'1px solid black',
    display: 'flex',

  },
  cardm:{
    padding: 2, 
    position: 'fixed', 
     zIndex: 10, 
     background: '#ffe000',
     top:315, 
    [theme.breakpoints.down('sm')]: {
      right:250,
      
    },
    [theme.breakpoints.up('sm')]: {
      right:250,
      
    },
  },
  cartDrawer: {
    width: 250,
  },
  cartItem: {
    padding: 5,
    background: '#f9fad7',
    marginBottom: 3,
    '&:hover': {
      background: '#feffb2',
    },
  },
  cartImg: {
    maxHeight: 40,
    maxWidth: 30,
    marginRight: 3,
    marginTop: 22,
    borderRadius: 5,
  },
  cartTxt: {
    textOverflow: 'ellipsis',
    width: 150,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cartDel: {
    float: 'right',
    padding: 0,
    fontSize: 15,
    height: 20,
    width: 20,
    position: 'absolute',
    right: 5,
  },
  cartBottom: {
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 1%,rgba(255,255,255,1) 19%,rgba(255,255,255,1) 100%)',
    paddingBottom: 5,
    marginTop: 'auto',
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    display: 'inline-block',
    width: 200,
  },
  cartBG: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
    opacity: 0.1,
    position: 'absolute',
    width: '100%',
    zIndex: -1,
  },
 

  loginImg: {
    display: 'block',
    margin: '5px auto',
    padding: 8,
    width: 45,
    borderRadius: 10,
    boxShadow: '-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.23)-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.23)box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.23)',
  },
  logo_txt: {
    height: 20,
  },
  error_msg: {
    display: 'flex',
    alignContent: 'center',
    fontSize: 12,
    padding: 5,
    background: '#fff0f0',
    color: 'darkred',
    margin: 4,
    borderRadius: 8,
  },
  profileDrpDwn: {
    position: 'absolute',
    padding: 5,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 130,
    '& > a': {
      display: 'flex',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      marginBottom: 5,
      padding: 3,
      borderRadius: 5,
    },
    '& > a:hover': {
      background: '#efefef',
    },
    '& > a > div': {
      fontWeight: 500,
      marginLeft: 5,
    },
  },
})

function TopHeader(props) {
  const [cart, setCart] = React.useState(props.cart)

  React.useEffect(() => {
    setCart(props.cart)
  }, [props.cart])

  const { classes } = props
  const [isAxios, setAxios] = React.useState(false)
  const [isFormErr, setFormErr] = React.useState(false)
  const [drpDwn, setDrpDwn] = React.useState(false)
  const [regForm, setRegForm] = React.useState(false)
  const [mobile, setMobile] = React.useState('')
  const [pass, setPass] = React.useState('')
  const [cartOpen, setCartOpen] = React.useState(false)
  //  reg form
  const [regStep, setRegStep] = React.useState(0)
  const [forgetStep, setForgetStep] = React.useState(0)
  const [name, setName] = React.useState('')
  const [regMob, setRegMob] = React.useState('')
  const [confirmationPin, setConfirmationPin] = React.useState('')
  const [pin, setPin] = React.useState('')
  const [regPass, setRegPass] = React.useState('')
  const [confRegPass, setConfRegPass] = React.useState('')

  function hndleCart() {

    if (cartOpen) {
      setCartOpen(false)
      props.toggleCart(0)
    } else {
      setCartOpen(true)
      props.toggleCart(200)
    }
  }

  function handleForgetModel()
  {
     props.setLoginModal(false)
     props.setForgetModal(true)
  }
  function handleLoginModel()
  {
    props.setLoginModal(true)
    props.setForgetModal(false)
  }

  // function regNext(e) {
  //   e.preventDefault()
  //   switch (regStep) {
  //     case 0:
  //       setAxios(true)
  //       // eslint-disable-next-line no-case-declarations
  //       const mob = regMob.replace(/(^880|^0|^88)/g, '')
  //       if (mob > 1111111111) {
  //         axios.post(ApiLink.sms_confirmation, { mob })
  //           .then(data => {

  //             setAxios(false)
  //             setConfirmationPin(atob(data.data.res))
  //             setRegStep(regStep + 1)
  //           })
  //           .catch(() => {
  //             setAxios(false)
  //           })
  //       } else {
  //         setAxios(false)
  //         setFormErr(true)
  //         setTimeout(() => {
  //           setFormErr(false)
  //         }, 3000);
  //       }

  //       break

  //     case 1:
  //       if (pin === confirmationPin) {
  //         setRegStep(regStep + 1)
  //       } else {
  //         setFormErr(true)
  //         setTimeout(() => {
  //           setFormErr(false)
  //         }, 3000)
  //       }
  //       break

  //     case 2:
  //       if (regPass === confRegPass) {
  //         setAxios(true)
  //         const params = new URLSearchParams()
  //         params.append('name', name)
  //         params.append('mob', regMob)
  //         params.append('pass', regPass)
  //         params.append('pass_con', confRegPass)

  //         axios.post(`${ApiLink.affiliate_biz_bazar}/LoginRegCtrl/sign_up`, params)
  //           .then(res => {
         
  //             if (res.data.success === 1) {
  //               props.setAuth(true, res.data.name)
  //               //  localStorage.setItem('_token', res.data._token)
  //               localStorage.setItem('_log', res.data.log)
  //               localStorage.setItem('name', res.data.name)
  //               setAxios(false)
  //               setRegStep(regStep + 1)
  //               setTimeout(() => {
  //                 props.setLoginModal(false)
  //               }, 4000);
  //             } else {
  //               setAxios(false)
  //             }
  //           })
  //           .catch(err => {
             
  //           })
  //       } else {
  //         setFormErr(true)
  //         setTimeout(() => {
  //           setFormErr(false)
  //         }, 3000)
  //       }
  //       break
  //     case 3:
  //       setTimeout(() => {
  //         props.setLoginModal(false)
  //       }, 2000);
  //       break
  //     default:
  //       break
  //   }
  // }

  function regNext(e) {
    e.preventDefault()
    switch (regStep) {
      case 0:
        setAxios(true)
        // eslint-disable-next-line no-case-declarations
        const mob = regMob.replace(/(^880|^0|^88)/g, '')
        if (mob > 1111111111) {
          setRegStep(regStep + 2)

          // axios.post(ApiLink.sms_confirmation, { mob })
          //   .then(data => {

          //     setAxios(false)
          //     setConfirmationPin(atob(data.data.res))
          //     setRegStep(regStep + 1)
          //   })
          //   .catch(() => {
          //     setAxios(false)
          //   })
        } else {
          setAxios(false)
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000);
        }

        break

      case 1:
        if (pin === confirmationPin) {
          setRegStep(regStep + 1)
        } else {
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000)
        }
        break

      case 2:
        if (regPass === confRegPass) {
          setAxios(true)
          const params = new URLSearchParams()
          params.append('name', name)
          params.append('mob', regMob)
          params.append('pass', regPass)
          params.append('pass_con', confRegPass)
          axios.post(`${ApiLink.affiliate_biz_bazar}/LoginRegCtrl/sign_up`, params)
          setRegStep(regStep + 1)
            .then(res => {
              if (res.data.success === 1) {
                props.setAuth(true, res.data.name)
                //  localStorage.setItem('_token', res.data._token)
                localStorage.setItem('_log', res.data.log)
                localStorage.setItem('name', res.data.name)
                setAxios(false)
                setRegStep(regStep + 1)
                setTimeout(() => {
                  props.setLoginModal(false)
                }, 4000);
              } else {
                setAxios(false)
              }
            })
            .catch(err => {
             
            })
        } else {
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000)
        }
        break
      case 3:
        setTimeout(() => {
          props.setLoginModal(false)
        }, 2000);
        break
      default:
        break
    }
  }
  function forgetPasswordNext(e) {
    e.preventDefault()
    switch (forgetStep) {
      case 0:
        setAxios(true)
         
        // eslint-disable-next-line no-case-declarations
        const mob = regMob.replace(/(^880|^0|^88)/g, '')
        
        const params = new URLSearchParams();
          params.append('mob', mob);

        if (mob > 1111111111) {
          
          axios({
            method: 'post',
            url: `${ApiLink.affiliate_biz_bazar}LoginRegCtrl/existUser`,
            data: params,
          }).then(data => {
             setAxios(false)
              if(data.data.status===1){
                setConfirmationPin(atob(data.data.res))
                setForgetStep(forgetStep + 1)
              }
              else{
                setForgetStep(forgetStep)
                setFormErr(true)
               setTimeout(() => {
               setFormErr(false)
               }, 3000);


              }
            })
            .catch(() => {
              setAxios(false)
            })
        } else {
          setAxios(false)
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000);
        }

        break

      case 1:
        if (pin === confirmationPin) {
          setForgetStep(forgetStep + 1)
        } else {
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000)
        }
        break

      case 2:
        if (regPass === confRegPass) {
          setAxios(true)
          const params = new URLSearchParams()
          params.append('mob', regMob)
          params.append('pass', regPass)
          params.append('pass_con', confRegPass)

          axios.post(`${ApiLink.affiliate_biz_bazar}/LoginRegCtrl/resetPass`, params)
            .then(res => {
         
              if (res.data.success === 1) {
                                
                setForgetStep(forgetStep + 1)
				setAxios(false)
                setTimeout(() => {
                  props.setForgetModal(false)
                }, 2000);
              } else {
                setAxios(false)
              }
            })
            .catch(err => {
             
            })
        } else {
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000)
        }
        break
      case 3:
        setTimeout(() => {
          props.setLoginModal(false)
          props.setForgetModal(false)
        }, 2000);
        break
      default:
        break
    }
  }


  function delCartItm(obj) {
    props.delFromCart(obj)
  }

  function hndleFrom(e) {
    // eslint-disable-next-line no-unused-expressions
    e.target.name === 'mob' ? setMobile(e.target.value) : setPass(e.target.value)
  }

  function login() {
    setAxios(true)
    setFormErr(false)
    const num = mobile

    const params = new URLSearchParams()
    params.append('mob', num)
    params.append('pass', pass)

    axios({
      method: 'post',
      url: `${ApiLink.affiliate_biz_bazar}LoginRegCtrl/sign_in`,
      data: params,
    })
      .then((res) => {
        setAxios(false)
        if (res.data.success === 1) {
          props.setAuth(true, res.data.name)
          localStorage.setItem('_token', res.data._token)
          localStorage.setItem('_log', res.data.log)
          localStorage.setItem('s', res.data.s)
          localStorage.setItem('name', res.data.name)
          props.setLoginModal(false)
          setAxios(false)
        } else {
          setAxios(false)
          setFormErr(true)
          setTimeout(() => {
            setFormErr(false)
          }, 3000)
        }
      })
      .catch(() => {
        setAxios(false)
      })
  }

  function logout() {
    props.setAuth(false, null)
    localStorage.removeItem('name')
    localStorage.removeItem('_token')
    localStorage.removeItem('_log')
  }

  function goDash() {

    const decodedData = JSON.parse(window.atob(localStorage.getItem('_log')))
    document.getElementById('__m').value = decodedData.mob
    document.getElementById('__p').value = decodedData.pass
    document.getElementById('login-form').submit()
  }

  function cartBuy() {
    if (props.auth) {
      if (props.cart.length > 0) {
        props.history.push('/order')
      }
    } else {
      props.setLoginModal(true)
    }
  }

let totalItem = 0
  
  let totalAmount = 0
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} position="fixed">
        <Toolbar>
          <IconButton
            onClick={() => props.togleDwr()}
            className={classes.menuButton}
            color="default"
            aria-label="Open drawer"
          >
            <MenuIcon />
          </IconButton>

          <Link to="/">
            <img className={classes.logo} src={logo} alt="Quick-Trade logo" />
          </Link>
          <Link to="/">
            <img
              className={classes.txtLogo}
              src={txtLogo}
              alt="Quick-Trade logo"
            />
          </Link>

          <div className={classes.search}>
            <InputBase
              onFocus={props.handleSearchIcnClr}
              onBlur={props.handleSearchIcnClr}
              placeholder="Search for any Products… (e.g:iPhone, Shirt, etc)"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            <IconButton className={classes.searchBtn} aria-label="Search">
              <SearchIcon style={{ color: props.srcIcn }} />
            </IconButton>
          </div>
          <div className={classes.grow} />

          <IconButton color="default">
            <Help />
          </IconButton>

          {!props.auth ? (
            <Button onClick={() => props.setLoginModal(true)}  color="default">
              <AccountCircle />

              <b>Login</b>
            </Button>
          )
            : (
              <ClickAwayListener onClickAway={() => setDrpDwn(false)}>
                <div style={{ position: 'relative' }}>
                  <Button color="default" onClick={() => setDrpDwn(!drpDwn)}>
                    <AccountCircle />
                    &nbsp;
                    <b>{props.user_name}</b>
                  </Button>
                  {drpDwn
                    && (
                      <Paper className={classes.profileDrpDwn}>
                        <ButtonBase onClick={goDash} component="a">
                          <Dashboard />
                          {' '}
                          <div>Dashboard</div>
                        </ButtonBase>
                        <ButtonBase onClick={logout} component="a">
                          <ExitToApp />
                          {' '}
                          <div>Logout</div>
                        </ButtonBase>
                      </Paper>
                    )}
                </div>
              </ClickAwayListener>
            )}

          <form id="login-form" action={`${ApiLink.affiliate_biz_bazar}LoginRegCtrl/quick_trade_login`} method="post">
            <input name="mob" id="__m" type="hidden" />
            <input name="pass" id="__p" type="hidden" />
          </form>

          <Dialog
            open={props.loginModal}
            maxWidth="md"
            onClose={() => props.setLoginModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {!regForm
                ? (
                  <div>
                    <div className="text-center">
                      <img className={classes.loginImg} src={logo} alt="logo" />
                      <img className={classes.logo_txt} src={txtLogo} alt="logo" />
                      <p>Simply Buy from Online Market</p>
                    </div>
                    <div className="text-center">
                      <form>
                        <label htmlFor="mob" style={{ color: 'gray' }}>
                          Login With Your Credentials
                          <br />
                          <TextField
                            error={isFormErr}
                            autoComplete="Mobile"
                            name="mob"
                            label="Mobile"
                            type="number"
                            value={mobile}
                            onChange={hndleFrom}
                            margin="dense"
                            variant="outlined"
                            required
                          />
                          <br />
                          <TextField
                            error={isFormErr}
                            autoComplete="Password"
                            name="pass"
                            type="password"
                            label="Password"
                            value={pass}
                            onChange={hndleFrom}
                            margin="dense"
                            variant="outlined"
                            required
                          />
                          <br />
                          <div className={classes.error_msg} style={{ display: !isFormErr && 'none' }}>
                            {' '}
                            <ErrorOutline fontSize="small" />
                            {' '}
                            &nbsp; Wrong Creadentials, Try again!
                          </div>
                          <Button
                            style={{ marginTop: 5, width: 221 }}
                            onClick={login}
                            variant="contained"
                            color="primary"
                          >
                            <b>a Login &nbsp;</b>
                            {isAxios ? <CircularProgress style={{ color: 'black' }} size={14} thickness={4} /> : <ChevronRightRounded />}
                          </Button>
                        </label>
                      </form>
                      <br />
                      <div id="forget_password">
                        <Button  onClick={handleForgetModel}  style={{
                            marginTop: -33,textDecoration:'inherit',marginLeft:101,fontSize:10, fontWeight: 'bold',color:'#c51162',
                          }} >forget password</Button>
                      </div>
                      <div>
                        <div>
                          <small>Don&apos;t Have any account ? </small>
                          </div>
                        <Button
                          style={{
                            marginTop: 5, width: 227, fontWeight: 'bold', background: '#f50057', color: 'white',
                          }}
                          onClick={() => setRegForm(true)}
                          variant="contained"
                        >
                          <AccountCircleOutlined />
                          {' '}
                          &nbsp; Registration 
                        </Button>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div>
                    <div className="text-center">
                      <img className={classes.loginImg} src={logo} alt="logo" />
                      <img className={classes.logo_txt} src={txtLogo} alt="logo" />
                      <p>Simply Buy from Online Market</p>
                    </div>
                    <div className="text-center">
                      <label htmlFor="Create Account" style={{ color: 'gray' }}>
                        Create A New Account
                        <br />
                        {regStep === 0
                          && (
                            <form onSubmit={regNext}>
                              <TextField
                                error={isFormErr}
                                autoComplete="Name"
                                name="name"
                                label="Your Name"
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                margin="dense"
                                variant="outlined"
                                required
                              />
                              <br />
                              <TextField
                                error={isFormErr}
                                autoComplete="tel"
                                name="mob"
                                type="number"
                                label="Your Mobile Number"
                                value={regMob}
                                onChange={e => setRegMob(e.target.value)}
                                margin="dense"
                                variant="outlined"
                                required
                              />
                              <br />
                              <Button
                                type="submit"
                                style={{ marginTop: 5, width: 221 }}
                                variant="contained"
                                color="primary"
                              >
                                <b>Next &nbsp;</b>
                                {isAxios ? <CircularProgress style={{ color: 'black' }} size={14} thickness={4} /> : <ChevronRightRounded />}
                              </Button>
                            </form>
                          )}
                        {regStep === 1
                          && (
                            <div>
                              <br />
                              <div className="success_msg">
                                <ErrorOutline />
                                <small>
                                  A Confirmation PIN sent in your
                                  <br />
                                  Mobile Number
                                  {' '}
                                  <span style={{ background: 'aliceblue' }}>{regMob}</span>
                                  <br />
                                  Insert this PIN and Proceed Next
                                </small>
                              </div>
                              <br />
                              <form onSubmit={regNext}>
                                <TextField
                                  error={isFormErr}
                                  autoComplete="off"
                                  name="pin"
                                  type="number"
                                  label="Confirmation PIN"
                                  value={pin}
                                  onChange={e => setPin(e.target.value)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                />
                                <br />
                                <Button
                                  type="submit"
                                  style={{ marginTop: 5, width: 221 }}
                                  variant="contained"
                                  color="primary"
                                >
                                  <b>Next &nbsp;</b>
                                  <ChevronRightRounded style={{ display: isAxios && 'none' }} />
                                  <CircularProgress style={{ display: !isAxios && 'none', color: 'black' }} size={14} thickness={4} />
                                </Button>
                                <br />
                                <Button
                                  onClick={() => setRegStep(regStep - 1)}
                                  type="button"
                                  style={{ marginTop: 5, width: 221, background: '#fff18e' }}
                                  variant="contained"
                                >
                                  <ChevronLeftRounded />
                                  <b>Back &nbsp;</b>
                                </Button>
                              </form>
                            </div>
                          )}
                        {regStep === 2
                          && (
                            <div>
                              <form onSubmit={regNext}>
                                <TextField
                                  error={isFormErr}
                                  autoComplete="off"
                                  name="Password"
                                  type="password"
                                  label="Password"
                                  value={regPass}
                                  onChange={e => setRegPass(e.target.value)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                />
                                <br />
                                <TextField
                                  error={isFormErr}
                                  autoComplete="off"
                                  name="c_pass"
                                  type="password"
                                  label="Confirmation Password"
                                  value={confRegPass}
                                  onChange={e => setConfRegPass(e.target.value)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                />
                                <br />
                                <Button
                                  type="submit"
                                  style={{ marginTop: 5, width: 221 }}
                                  variant="contained"
                                  color="primary"
                                >
                                  <b>Next &nbsp;</b>
                                  <ChevronRightRounded style={{ display: isAxios && 'none' }} />
                                  <CircularProgress style={{ display: !isAxios && 'none', color: 'black' }} size={14} thickness={4} />
                                </Button>
                                <br />
                                <Button
                                  onClick={() => setRegStep(regStep - 1)}
                                  
                                  type="button"
                                  style={{ marginTop: 5, width: 221, background: '#fff18e' }}
                                  variant="contained"
                                >
                                  <ChevronLeftRounded />
                                  <b>Back &nbsp;</b>
                                </Button>
                              </form>
                            </div>
                          )}
                        {regStep === 3
                          && (
                            <div>
                              <br />
                              <div className="success_msg">
                                <ErrorOutline />
                                Successfully Complete Registration
                                <br />
                                Thank You
                              </div>
                            </div>
                          )}
                      </label>
                      <div className={classes.error_msg} style={{ display: !isFormErr && 'none' }}>
                        <ErrorOutline fontSize="small" />
                        {' '}
                        &nbsp; Wrong Creadentials, Try again!
                      </div>

                      <br />
                      <div>
                        <div><small>Already Have an account ? </small></div>
                        <Button
                          style={{
                            marginTop: 5, width: 227, fontWeight: 'bold', background: '#b8d3ee',
                          }}
                          onClick={() => setRegForm(false)}
                          
                          variant="text"
                        >
                          <AccountCircle />
                          {' '}
                          &nbsp; Login
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
            </DialogContent>
          </Dialog>



          <Dialog
            open={props.forgetModal}
            maxWidth="md"
            onClose={() => props.setForgetModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
            <div>
                    <div className="text-center">
                      <img className={classes.loginImg} src={logo} alt="logo" />
                      <img className={classes.logo_txt} src={txtLogo} alt="logo" />
                      <p>Simply Buy from Online Market</p>
                    </div>
                    <div className="text-center">
                      <label htmlFor="Create Account" style={{ color: 'gray' }}>
                      Please enter your mobile address below. 
                        <br />
                        {forgetStep === 0
                          && (
                            <form onSubmit={forgetPasswordNext}>
                            
                              <TextField
                                error={isFormErr}
                                autoComplete="tel"
                                name="mob"
                                type="number"
                                label="Your Mobile Number"
                                value={regMob}
                                onChange={e => setRegMob(e.target.value)}
                                margin="dense"
                                variant="outlined"
                                required
                              />
                              <br />
                              <Button
                                type="submit"
                                style={{ marginTop: 5, width: 221 }}
                                variant="contained"
                                color="primary"
                              >
                                <b>Next &nbsp;</b>
                                {isAxios ? <CircularProgress style={{ color: 'black' }} size={14} thickness={4} /> : <ChevronRightRounded />}
                              </Button>
                            </form>
                          )}
                        {forgetStep === 1
                          && (
                            <div>
                              <br />
                              <div className="success_msg">
                                <ErrorOutline />
                                <small>
                                  A Confirmation PIN sent in your
                                  <br />
                                  Mobile Number
                                  {' '}
                                  <span style={{ background: 'aliceblue' }}>{regMob}</span>
                                  <br />
                                  Insert this PIN and Proceed Next
                                </small>
                              </div>
                              <br />
                              <form onSubmit={forgetPasswordNext}>
                                <TextField
                                  error={isFormErr}
                                  autoComplete="off"
                                  name="pin"
                                  type="number"
                                  label="Confirmation PIN"
                                  value={pin}
                                  onChange={e => setPin(e.target.value)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                />
                                <br />
                                <Button
                                  type="submit"
                                  style={{ marginTop: 5, width: 221 }}
                                  variant="contained"
                                  color="primary"
                                >
                                  <b>Next &nbsp;</b>
                                  <ChevronRightRounded style={{ display: isAxios && 'none' }} />
                                  <CircularProgress style={{ display: !isAxios && 'none', color: 'black' }} size={14} thickness={4} />
                                </Button>
                                <br />
                                <Button
                                  onClick={() => setForgetStep(forgetStep - 1)}
                                  type="button"
                                  style={{ marginTop: 5, width: 221, background: '#fff18e' }}
                                  variant="contained"
                                >
                                  <ChevronLeftRounded />
                                  <b>Back &nbsp;</b>
                                </Button>
                              </form>
                            </div>
                          )}
                        {forgetStep === 2
                          && (
                            <div>
                              <form onSubmit={forgetPasswordNext}>
                                <TextField
                                  error={isFormErr}
                                  autoComplete="off"
                                  name="Password"
                                  type="password"
                                  label="Password"
                                  value={regPass}
                                  onChange={e => setRegPass(e.target.value)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                />
                                <br />
                                <TextField
                                  error={isFormErr}
                                  autoComplete="off"
                                  name="c_pass"
                                  type="password"
                                  label="Confirmation Password"
                                  value={confRegPass}
                                  onChange={e => setConfRegPass(e.target.value)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                />
                                <br />
                                <Button
                                  type="submit"
                                  style={{ marginTop: 5, width: 221 }}
                                  variant="contained"
                                  color="primary"
                                >
                                  <b>Next &nbsp;</b>
                                  <ChevronRightRounded style={{ display: isAxios && 'none' }} />
                                  <CircularProgress style={{ display: !isAxios && 'none', color: 'black' }} size={14} thickness={4} />
                                </Button>
                                <br />
                                <Button
                                  onClick={() => setForgetStep(forgetStep - 1)}
                                  
                                  type="button"
                                  style={{ marginTop: 5, width: 221, background: '#fff18e' }}
                                  variant="contained"
                                >
                                  <ChevronLeftRounded />
                                  <b>Back &nbsp;</b>
                                </Button>
                              </form>
                            </div>
                          )}
                        {forgetStep === 3
                          && (
                            <div>
                              <br />
                              <div className="success_msg">
                                <ErrorOutline />
                                 Your Password Successfully Change
                                <br />
                                Thank You
                              </div>
                            </div>
                          )}
                      </label>
                      <div className={classes.error_msg} style={{ display: !isFormErr && 'none' }}>
                        <ErrorOutline fontSize="small" />
                        {' '}
                        &nbsp; Wrong Creadentials, Try again!
                      </div>

                      <br />
                      <div>
                        
                        <Button
                          style={{
                            marginTop: 5, width: 227, fontWeight: 'bold', background: '#b8d3ee',
                          }}
                          onClick={handleLoginModel}
                          
                          variant="text"
                        >
                          <AccountCircle />
                          {' '}
                          &nbsp; Login
                        </Button>
                      </div>
                    </div>
                  </div>
            </DialogContent>

          </Dialog>

          



        </Toolbar>
      </AppBar>

      <div>


        {!cartOpen ? (
                   <IconButton
            style={{ boxShadow: 'rgb(136, 136, 136) 5px 10px 18px',padding: '6px', borderRadius: '7px', border: 'none' }}
            className={classes.cardMiddle}
            onClick={hndleCart}
          >

            <StyledBadge badgeContent={props.cart.length} color="secondary">
              <ShoppingCart
                fontSize="inherit"
                style={{ fontSize: '35px', color: '' }}
              />
            </StyledBadge>
          </IconButton>
            )
            : (
                // eslint-disable-next-line react/jsx-indent
                  <IconButton
                  onClick={hndleCart}
                  style={{ boxShadow: 'rgb(136, 136, 136) 5px 10px 18px', borderRadius: '7px', padding: '6px', border: 'none' }}
                  className={classes.cardm}
                >
                  <StyledBadge badgeContent={props.cart.length} color="secondary">
                    <ShoppingCart
                      fontSize="inherit"
                      style={{ fontSize: '35px', color: '' }}
                    />
                  </StyledBadge>

                </IconButton>
            )
        }


      </div>


      {/* drawer */}
      <SwipeableDrawer
        className={classes.cartDrawer}
        anchor="right"

        position="fixed"
        variant={window.innerWidth > 1024 ? 'persistent' : 'temporary'}
        open={cartOpen}
        onClose={() => setCartOpen(false)}
        onOpen={() => setCartOpen(true)}
        classes={{ paper: classes.cartDrawer }}
      >
        <div
          aria-label="Close Cart"
          tabIndex={0}
          role="button"
          onClick={() => setCartOpen(false)}
          onKeyDown={() => setCartOpen(false)}
        />

        <div className={classes.toolbar} />
        <br />
        <br />
        <br />


        <List style={{ overflowY: 'scroll' }}>

          
          {cart.length <= 0 && <ListItem><ListItemText style={{ textAlign: 'center' }} primary="Cart Empty" /></ListItem>}
          {cart.map((item) => {
            totalItem += item.quan
            totalAmount += item.price * item.quan

            return (
              <ListItem button key={Math.random()} className={classes.cartItem}>
                <div style={{ width: '100%' }}>
                  <IconButton onClick={() => delCartItm({ pdtId: item.id, marcentId: item.marchant_id })} className={classes.cartDel}>✖</IconButton>
                  <div className={classes.cartTxt}><small><b>{item.name}</b></small></div>
                  <div>
                    <small>
                      <i>
                        -
                        {item.sub_cat}
                      </i>
                    </small>

                  </div>
                  <div>
                    Price:
                    {' '}
                    {item.price}
                    {' '}
                    x
                    {' '}
                    {item.quan}
                    {' '}
                    Pcs
                  </div>
                </div>
                <img src={`${ApiLink.img_link}/mini/min${item.thumb}`} className={classes.cartImg} alt="cart thumbnail" />
              </ListItem>
            )
          })}

        </List>

        <div className={classes.cartBG}><ShoppingCart style={{ fontSize: 100 }} /></div>

        <div className={classes.cartBottom}>
          <hr style={{ border: '.5px solid lightgray' }} />
          <div>
            Total Item :
            {' '}
            <b>
              {cart.length}
              {' '}
              /=
            </b>
          </div>
          <div>
            Total :
            {' '}
            <b>
              ৳
              {' '}
              {totalAmount}
              {' '}
              /=
            </b>
          </div>

          <Button onClick={cartBuy} style={{ boxShadow: 'none', padding: '2px 40px', marginTop: 5 }} variant="contained" color="primary">
            <b>Buy </b>
            {' '}
            &nbsp;
            {' '}
            <ShoppingBasketOutlined />
            {' '}
          </Button>
        </div>

      </SwipeableDrawer>
    </div>
  )
}

TopHeader.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    openDrawer: state.openDrawer,
    auth: state.auth,
    user_name: state.user_name,
    loginModal: state.loginModal,
    forgetModal:state.forgetModal,
   
  }
}

function mapDispatchToProps(dispatch) {
  return {
    togleDwr: () => dispatch({ type: 'toggleDrawer' }),
    toggleCart: margin => dispatch({ type: 'toggleCart', payload: margin }),
    delFromCart: obj => dispatch({ type: 'delFromCart', payload: obj }),
    snackShow: msg => dispatch({ type: 'snackShow', payload: msg }),
    snackClose: msg => dispatch({ type: 'snackClose', payload: msg }),
    setAuth: (auth, name) => dispatch({ type: 'setAuth', payload: { auth, user_name: name } }),
    setLoginModal: isLoginModal => dispatch({ type: 'setLoginModal', payload: isLoginModal }),
    setForgetModal: isForgetModal => dispatch({ type: 'setForgetModal', payload: isForgetModal }),
  
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopHeader))
