import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core/'
import ApiLink from '../components/ApiLink'


const styles = {
  content: {
    // padding: '8px',
    color: '#4caf50 !important',
    textAlign: 'center',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
    padding: '31px 30px 30px 0px',
    marginTop: '40px',
    // background: 'white !important',
    // margin: '10px auto auto auto;',
  },
  main: {

  },

}

function Success(props) {
  const style = props.classes;

  function goDash() {
    const decodedData = JSON.parse(window.atob(localStorage.getItem('_log')))

    document.getElementById('__mobile').value = decodedData.mob
    document.getElementById('__password').value = decodedData.pass
    document.getElementById('order').submit()
  }
  // eslint-disable-next-line no-unused-expressions

  return (
    <>
      <form id="order" action={`${ApiLink.affiliate_biz_bazar}LoginRegCtrl/quick_login_order`} method="post">
        <input name="mob" id="__mobile" type="hidden" />
        <input name="pass" id="__password" type="hidden" />
      </form>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={11} sm={11} xs={11}>
          <div className={`${style.content}`}>
                      Order Complete Successfully ✔
            <br />
            {' '}
                      You Will get update about delivery process.Delivary Time 3-4 Working Days
            <br />
            <br />
            <div>
              <Button style={{ backgroundColor: '#ffe000', boxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)' }}>
                <Link to="/">
                          Contoniue Shopping
                </Link>
              </Button>
                        &nbsp;&nbsp;
              <Button onClick={goDash} style={{ backgroundColor: '#009dff', boxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)' }}>
                        View Orders
              </Button>
            </div>


          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(Success);
