import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Fab from '@material-ui/core/Fab'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import { Loader } from '../components/Loader'
import ApiLink from '../components/ApiLink'
import HomeProductThumb from '../components/HomeProductThumb'
import cover1 from '../images/cover/biz1.jpg'
import cover2 from '../images/cover/biz2.jpg'
import cover3 from '../images/cover/biz3.jpg'
import cover4 from '../images/cover/biz4.jpg'
import mobile from '../images/mobile.jpeg'
import elec from '../images/elec.jpeg'
import women from '../images/women.jpeg'
import men from '../images/men.png'
import kids from '../images/kids.png'
import foodzone from '../images/foodzone.png'
import automative from '../images/biz-bazar-auto-min.jpg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// "react-responsive-carousel": "^3.1.49",

// eslint-disable-next-line object-curly-newline
const PrvArw = props => <Fab onClick={props.onClick} style={{ top: '33%', left: -40, width: 40, height: 40, position: 'absolute', background: 'white', padding: 5 }}><ChevronLeft /></Fab>

// eslint-disable-next-line object-curly-newline
const NxtArw = props => <Fab onClick={props.onClick} style={{ top: '33%', right: -40, width: 40, height: 40, position: 'absolute', background: 'white', padding: 5 }}><ChevronRight /></Fab>

const SliderItem = props => {
  // let item = JSON.parse(props.catChild)
  const item = props.catChild
  return (
    <div key={item.id}>
      <div className="itm">
        <span><Link to={item.url}>{item.sub_cat_name}</Link></span>
        <img src={`${ApiLink.resource_link}/images/sub_cats/${item.image}`} alt="quick trade" />
      </div>
    </div>
  )
}
const Thumbs = p => {
  if (p.pdt.length < 1) {
    return null
  }
  return (
    <div>
      <br />
      <br />
      <div className="pdt-tmb-wrp">
        <div>
          {p.pdt.map(pdt => <HomeProductThumb key={pdt.id} img={pdt.thumb} id={pdt.id} price={pdt.price} name={pdt.name} />)}
        </div>
      </div>
    </div>
  )
}
class Home extends Component {
  state = {
    products: [],
  }


  _mounted = false;

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    this.props.match.params.referer_id != null && this.props.setReferer(this.props.match.params.referer_id - 1000)

    axios.get(ApiLink.leatest_pdt_link).then(r => {
      this._mounted = true
      if (r.data.length > 0 && this._mounted) {
        if (this._mounted) {
          this.setState({
            products: r.data,
          });
        }
      } else if (this._mounted) {
        this.setState({
          products: null,
        });
      }
    })
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const state = this.state;
    const settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: <PrvArw to="prev" />,
      nextArrow: <NxtArw to="next" />,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: false,
          },
        },
      ],
    };
    const settings2 = {
      autoplay: true,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
    };

    if (state.products == null) {
      return <h4> No Product Found</h4>;
    } if (state.products.length > 0 && this.props.cats != null) {
      return (
        <div style={{ backgroundColor: '#fff' }}>
          {/* <div id="home">
            <h1 id="home-title">Simply Buy & <br /> Trade on Online</h1>
            <h2 id="home-subtitle">-Quick-Trade</h2>
            <img id="home-img-1" src={cover} alt="quick trade" />
            <img id="man" src={man} alt="quick trade" />
            <img id="box" src={box} alt="quick trade" />
            <img id="card" src={card} alt="quick trade" />
            <img id="mob" src={mob} alt="quick trade" />
          </div> */}

          <Slider {...settings2}>
            <div className="cover">
              <img src={cover4} alt="quick trade" />
            </div>
            <div className="cover">
              <img src={cover1} alt="quick trade" />
            </div>
            <div className="cover">
              <img src={cover2} alt="quick trade" />
            </div>
            <div className="cover">
              <img src={cover3} alt="quick trade" />
            </div>

          </Slider>
          <br />
          <div className="cats-title"><span>Popular Items</span></div>

          <Thumbs pdt={state.products[7]} />
          <Thumbs pdt={state.products[8]} />

          <img className="cat-cover" src={elec} alt="Electronics Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[4].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[4].children.map((itm) => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[0]} />

          <img className="cat-cover" src={mobile} alt="Mobile Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[2].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[2].children.map(itm => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[1]} />

          <img className="cat-cover" src={men} alt="Men Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[0].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[0].children.map(itm => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[3]} />

          <img className="cat-cover" src={women} alt="Women Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[1].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[1].children.map(itm => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[2]} />

          <img className="cat-cover" src={kids} alt="Kids Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[6].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[6].children.map(itm => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[4]} />

          <img className="cat-cover" src={automative} alt="Automative Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[9].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[9].children.map(itm => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[5]} />

          <img className="cat-cover" src={foodzone} alt="Foodzone Product mali-mart" />
          <br />
          <div className="cats-title"><span>{this.props.cats[10].category_name}</span></div>
          <br />
          <div className="cats">
            <Slider {...settings}>
              {this.props.cats[10].children.map(itm => <SliderItem key={Math.random()} catChild={itm} />)}
            </Slider>
          </div>

          <Thumbs pdt={state.products[6]} />
        </div>
      );
    }
    return <Loader />;
  }
}


const mapStateToProps = state => ({
  cats: state.category,
  referer: state.referer,
})
const mapDispatchToProps = (dispatch) => ({
  setReferer: val => { dispatch({ type: 'setReferer', payload: val }) },
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
