import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Link } from 'react-router-dom'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = (theme) => ({
  thum: {
    color: '#7b6c00',
    background: '#fffbde',
    width: 85,
    height: 85,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 20,
    margin: 10,
    textAlign: 'center',
    boxShadow: ' 0px 3px 5px -2px rgb(234, 228, 107);',
    '& > h5': {
      margin: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: 75,
      fontSize: 'smaller',
    },
  },
});

// const LinkRef = React.forwardRef((props,ref) => <Link innerRef={ref} {...props} />)

function SubCategoryThumb(props) {
  const { cats } = props;
  return cats.map(item => (
    <ButtonBase key={item.id} className={props.classes.thum} to={item.url} component={Link}>
      <SvgIcon>
        <path d={props.catIcon} />
      </SvgIcon>
      <h5>{item.sub_cat_name}</h5>
    </ButtonBase>
  ));
}

SubCategoryThumb.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubCategoryThumb);
