/* eslint-disable no-unused-vars */
import React from 'react'

function Loader() {
  return (
    <div style={{
      display: 'flex', justifyContent: 'center', height: '60vh', alignItems: 'center',
    }}
    >
      <svg width="50" height="30">
        <rect x="0" y="15" width="7" height="2" rx="0" fill="#1D2951">
          <animate
            id="rectsize1"
            attributeName="height"
            from="2"
            to="30"
            dur=".1s"
            begin="0s; rectsize5.end"
          />
          <animate
            attributeName="y"
            from="15"
            to="0"
            dur=".1s"
            begin="0s; rectsize5.end"
          />
          <animate
            attributeName="height"
            from="30"
            to="2"
            dur=".2s"
            begin="rectsize1.end"
          />
          <animate
            attributeName="y"
            from="0"
            to="15"
            dur=".2s"
            begin="rectsize1.end"
          />
        </rect>
        <rect x="10" y="15" width="7" height="2" rx="0" fill="#1034A6">
          <animate
            id="rectsize2"
            attributeName="height"
            from="2"
            to="30"
            dur=".1s"
            begin="rectsize1.end"
          />
          <animate
            attributeName="y"
            from="15"
            to="0"
            dur=".1s"
            begin="rectsize1.end"
          />
          <animate
            attributeName="height"
            from="30"
            to="2"
            dur=".2s"
            begin="rectsize2.end"
          />
          <animate
            attributeName="y"
            from="0"
            to="15"
            dur=".2s"
            begin="rectsize2.end"
          />
        </rect>
        <rect x="20" y="15" width="7" height="2" rx="0" fill="#0F52BA">
          <animate
            id="rectsize3"
            attributeName="height"
            from="2"
            to="30"
            dur=".1s"
            begin="rectsize2.end"
          />
          <animate
            attributeName="y"
            from="15"
            to="0"
            dur=".1s"
            begin="rectsize2.end"
          />
          <animate
            attributeName="height"
            from="30"
            to="2"
            dur=".2s"
            begin="rectsize3.end"
          />
          <animate
            attributeName="y"
            from="0"
            to="15"
            dur=".2s"
            begin="rectsize3.end"
          />
        </rect>
        <rect x="30" y="15" width="7" height="2" rx="0" fill="#1034A6">
          <animate
            id="rectsize4"
            attributeName="height"
            from="2"
            to="30"
            dur=".1s"
            begin="rectsize3.end"
          />
          <animate
            attributeName="y"
            from="15"
            to="0"
            dur=".1s"
            begin="rectsize3.end"
          />
          <animate
            attributeName="height"
            from="30"
            to="2"
            dur=".2s"
            begin="rectsize4.end"
          />
          <animate
            attributeName="y"
            from="0"
            to="15"
            dur=".2s"
            begin="rectsize4.end"
          />
        </rect>
        <rect x="40" y="15" width="7" height="2" rx="0" fill="#1D2951">
          <animate
            id="rectsize5"
            attributeName="height"
            from="2"
            to="30"
            dur=".1s"
            begin="rectsize4.end"
          />
          <animate
            attributeName="y"
            from="15"
            to="0"
            dur=".1s"
            begin="rectsize4.end"
          />
          <animate
            attributeName="height"
            from="30"
            to="2"
            dur=".2s"
            begin="rectsize5.end"
          />
          <animate
            attributeName="y"
            from="0"
            to="15"
            dur=".2s"
            begin="rectsize5.end"
          />
        </rect>
      </svg>

      <h4 style={{ position: 'absolute', marginTop: 30 }}>Loading..</h4>
    </div>
  )
}

function ImgLoader(prop) {
  return (
    <div
      title="Loading..."
      style={
        {
          background: 'rgba(0, 0, 0, 0.21)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: prop.display,
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    >
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="24px"
        height="30px"
        viewBox="0 0 24 30"
        style={{ enableBackground: 'new 0 0 50 50' }}
      >
        <rect x="0" y="13" width="4" height="5" fill="white">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="10" y="13" width="4" height="5" fill="white">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="20" y="13" width="4" height="5" fill="white">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>

  )
}

export { Loader, ImgLoader }
