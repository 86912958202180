
const baselink = 'https://myadmin.mali-mart.com/'
const affiliate = 'https://work.mali-mart.com/'
const ApiLink = {
  cat_link: `${baselink}/api/category`,
  product_list_link: `${baselink}/api/productList/`,
  sub_product_list_link: `${baselink}/api/subProductList/`,
  resource_link: baselink,
  img_link: `${baselink}/images/products/`,
  product_link: `${baselink}/api/product/`,
  leatest_pdt_link: `${baselink}/api/leatest_pdt`,
  send_order: `${baselink}/api/create_order`,
  affiliate_biz_bazar: affiliate,
  sms_confirmation: `${baselink}/api/sms_confirmation`,
  cartOrder: `${baselink}/api/cartOrder`,
  cashback: `${baselink}/api/cashback-product`,
  userblance: `${affiliate}/ProfileCtrl/cashBlance`,
}
export default ApiLink
