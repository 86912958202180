import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import axios from 'axios'
import MagicGrid from 'magic-grid-react'
import ProductThumb from './ProductThumb'
import ApiLink from './ApiLink'
import { Loader } from './Loader'

const styles = () => ({
  masonry: {
    columns: 5,
    textAlign: 'center',
    columnGap: 1,
  },
});

const MagicGridConfig = {
  animate: true,
  gutter: 10,
  useTransform: true,
  useMin: true,
  items: 10,
};

const Item = arr => arr.products.map(pdt => <ProductThumb key={pdt.id} pdt={pdt} cat_url={arr.cat_url} pdt_id={pdt.id} pdt_name={pdt.name} pdt_price={pdt.d_price} img={pdt.thumb} />);

class ProductList extends React.Component {
  state = {
    products: [],
  };


  _mounted = true;

  componentDidMount() {
    this._mounted = true;
    axios.get(ApiLink.product_list_link + this.props.cat_id).then(r => {
      if (r.data.length > 0 && this._mounted) {
        if (this._mounted) {
          this.setState({
            products: r.data,
          });
        }
      } else if (this._mounted) {
        this.setState({
          products: null,
        });
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const state = this.state;
    if (state.products == null) {
      return <h4> No Product Found</h4>;
    } if (state.products.length > 0) {
     
      return (
        <MagicGrid {...MagicGridConfig}>
          <Item products={state.products} cat_url={this.props.cat_url} />
        </MagicGrid>
      );
    }
    return <Loader />;
  }
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ProductList);
