import React, { Suspense } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TopHeader from './TopHeader'
import Navbar from './Navbar'
import ApiLink from '../ApiLink'
import Snackbar from '@material-ui/core/Snackbar'


class TopContainer extends React.Component {
  constructor() {
    super()
    // eslint-disable-next-line no-unused-expressions
    localStorage.getItem('cart') === null && localStorage.setItem('cart', '[]')
	localStorage.getItem('marcent') === null && localStorage.setItem('marcent', '[]')
  }

  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    srcIcn: 'grey',
    listOpen: false,
    menuItems: null,
    // settings: null,
    drpDwn: [0, false],
  }

  componentDidMount() {
    axios.get(ApiLink.cat_link).then(rs => {
      this.props.setCats(rs.data)
      const result = rs.data;
      this.setState({
        menuItems: result,
      });
    });

    axios.get(`${ApiLink.resource_link}/api/settings`)
      .then(rs => {
        this.props.setSettings(rs.data[0])
      })
  }

  handleDrpDwn = item => {
    this.setState(prev => (prev.drpDwn[0] === item ? { drpDwn: [item, !prev.drpDwn[1]] } : { drpDwn: [item, true] }));
  };

  handleSearchIcnClr = e => {
    this.setState({
      srcIcn: e.type === 'focus' ? '#ffe000' : 'black',
    });
  };


  render() {
    return (
      <>
        <TopHeader
          history={this.props.history}
          listOpen={this.state.listOpen}
          anchorEl={this.state.anchorEl}
          mobileMoreAnchorEl={this.state.mobileMoreAnchorEl}
          srcIcn={this.state.srcIcn}
          drwrOpen={this.state.drwrOpen}
          handleDrpDwn={this.handleDrpDwn}
          handleSearchIcnClr={this.handleSearchIcnClr}
          handleDrawerOpen={this.handleDrawerOpen}
        />

        <Navbar
          history={this.props.history}
          menuItems={this.state.menuItems}
          drpDown={this.state.drpDwn}
          handleDrawerOpen={this.handleDrawerOpen}
          mainDivMargin={this.state.mainDivMargin}
          drwrOpen={this.state.drwrOpen}
          listOpen={this.state.listOpen}
          handleDrpDwn={this.handleDrpDwn}
        />
        <Snackbar
          open={this.props.snackOpen}
          onClose={this.props.snackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.snackMsg}</span>}
        />
      </>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  setSettings: val => dispatch({ type: 'settings', payload: val }),
  setCats: cats => dispatch({ type: 'setCategory', payload: cats }),

});

const mapStateToProps = state => ({
  snackOpen: state.snackOpen,
  snackMsg: state.snackMsg,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopContainer));
