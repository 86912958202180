const initState = {
  openDrawer: !(window.innerWidth < 1025),
  mainDivMargin: window.innerWidth < 1025 ? 0 : 220,
  mainDivMarginRight: 0,
  settings: { number: 'none' },
  category: null,
  referer: null,
  snackOpen: false,
  snackMsg: '...',
  loginModal: false,
  cart: JSON.parse(localStorage.getItem('cart')) === null ? [] : JSON.parse(localStorage.getItem('cart')),
  marcent: JSON.parse(localStorage.getItem('marcent')) === null ? [] : JSON.parse(localStorage.getItem('marcent')),
  auth: localStorage.getItem('_token') !== null,
  user_name: localStorage.getItem('name') === null ? null : localStorage.getItem('name'),
}

function rootReducer(state = initState, action) {
  switch (action.type) {
    case 'toggleDrawer': {
      return {
        ...state,
        openDrawer: !state.openDrawer,
        mainDivMargin: window.innerWidth > 1024 && state.mainDivMargin === 0 ? 220 : 0,
      }
    }
    case 'toggleCart': {
      return {
        ...state,
        mainDivMarginRight: window.innerWidth > 1025 && action.payload,
      }
    }
    case 'settings': {
      return {
        ...state,
        settings: action.payload,
      }
    }
    case 'setCategory': {
      return {
        ...state,
        category: action.payload,
      }
    }
    case 'setReferer': {
      return {
        ...state,
        referer: action.payload,
      }
    }

    case 'snackShow': {
      return {
        ...state,
        snackOpen: true,
        snackMsg: action.payload,
      }
    }
    case 'snackClose': {
      return {
        ...state,
        snackOpen: false,
      }
    }
    case 'addToCart': {
      const newCart = [...state.cart, action.payload]
      localStorage.setItem('cart', JSON.stringify(newCart))
      return {
        ...state,
        cart: newCart,
      }
    }
    case 'delFromCart': {
      const updatedCart = state.cart.filter(item => item.id !== action.payload.pdtId)
      const marcentExist = state.cart.filter(item => item.marchant_id === action.payload.marcentId)
      let marcentUpdate = state.marcent
      if (marcentExist.length <= 1) {
        marcentUpdate = state.marcent.filter(item => item.marchant_id !== action.payload.marcentId)
      }
      localStorage.setItem('cart', JSON.stringify(updatedCart))
      localStorage.setItem('marcent', JSON.stringify(marcentUpdate))
      return {
        ...state,
        cart: updatedCart,
        marcent: marcentUpdate,
      }
    }
    case 'clearCart': {
      localStorage.removeItem('cart')
      return {
        ...state,
        cart: [],
      }
    }
    case 'clearMarcent': {
      localStorage.removeItem('marcent')
      return {
        ...state,
        marcent: [],
      }
    }
    case 'qtyChange': {
      // eslint-disable-next-line no-confusing-arrow
      const qtyUpdate = state.cart.map(item => item.id === action.payload.pdtId
        ? { ...item, quan: action.payload.quan > 0 ? action.payload.quan : 1 } : item)

      localStorage.setItem('cart', JSON.stringify(qtyUpdate))
      return {
        ...state,
        cart: qtyUpdate,
      }
    }
    case 'updateCart': {
      const newItem = state.cart.map(item => (item.id === action.payload.id
        ? { ...item, quan: item.quan + action.payload.quan }
        : item))
      localStorage.setItem('cart', JSON.stringify(newItem))
      return {
        ...state,
        cart: newItem,
      }
    }
    case 'marcentAdd': {
      const marcentAdd = [...state.marcent, action.payload]
      localStorage.setItem('marcent', JSON.stringify(marcentAdd))
      return {
        ...state,
        marcent: marcentAdd,
      }
    }

    case 'setAuth': {
      return {
        ...state,
        auth: action.payload.auth,
        user_name: action.payload.user_name,
      }
    }
    case 'setLoginModal': {
      return {
        ...state,
        loginModal: action.payload,
      }
    }
	
	 case 'setForgetModal': {
      return {
        ...state,
        forgetModal: action.payload,
      }
    }
    default: return state
  }
}

export default rootReducer
